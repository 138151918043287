import { gql } from 'graphql-tag';
import flattenConnection from '@watershed/shared-universal/utils/flattenConnection';
import { GQPermissionType } from '@watershed/shared-universal/generated/graphql';
import useHasPermission from '../../utils/useHasPermission';
import { useReportSummariesQuery } from '@watershed/shared-frontend/generated/urql';

gql`
  fragment ReportSummary on Report {
    id
    name
    updatedAt
  }

  fragment CustomReportSummary on FootprintCustomReport {
    id
    name
    updatedAt
  }

  query ReportSummaries($isAdmin: Boolean!) {
    formalReports(last: 5) {
      edges {
        node {
          ...ReportSummary
        }
      }
    }
    footprintAnalysis @include(if: $isAdmin) {
      id
      customReports {
        ...CustomReportSummary
      }
    }
  }
`;

export function useRecentReportSummaries() {
  const isAdmin = useHasPermission([GQPermissionType.Admin]);

  const [{ data, fetching: isLoading }] = useReportSummariesQuery({
    variables: { isAdmin },
  });

  const reports = flattenConnection(data?.formalReports) || [];
  const customReports = isAdmin
    ? data?.footprintAnalysis?.customReports || []
    : [];

  const recentReports = [...reports, ...customReports]
    .sort(
      (a, b) =>
        new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    )
    .slice(0, 5);

  return { recentReports, isLoading };
}
