import {
  TextFieldMultiline,
  TextFieldMultilineNonFormik,
} from '@watershed/ui-core/components/Form/TextField';
import { ReactNode, useState } from 'react';
import { GQCreatePermissionItemInput } from '@watershed/shared-universal/generated/graphql';
import { useLingui } from '@lingui/react/macro';
import { Menu, MenuItem, ListItemText } from '@mui/material';
import useLocale from '@watershed/intl/frontend/useLocale';
import { UnexpectedError } from '@watershed/shared-universal/errors/UnexpectedError';
import { formatObjectName } from '@watershed/shared-universal/utils/AddUserPermissionsUtils';
import { VALUE_MAPPING_OBJECT_NAME } from '@watershed/shared-universal/utils/permissionUtils';
import invariant from 'invariant';
import useSnackbar from '@watershed/shared-frontend/hooks/useSnackbar';
import { useAddPermissionMutation } from '../generated/urql';
import enqueueMutationSnackbar from '../utils/enqueueMutationSnackbar';
import ConfirmPermissionDialog from './ConfirmPermissionDialog';

export function MentionsPopover({
  users,
  anchorEl,
  onClose,
  onClick,
  setAnchorEl,
  handleKeyDown,
}: {
  users: Array<{ id: string; name: string }>;
  anchorEl?: HTMLTextAreaElement | null;
  onClose: () => void;
  onClick: (user: { id: string; name: string }) => void;
  setAnchorEl: (anchorEl: HTMLTextAreaElement | null) => void;
  handleKeyDown: (event: React.KeyboardEvent<HTMLLIElement>) => void;
}) {
  if (users.length === 0) {
    onClose();
  }

  return (
    <Menu
      open={anchorEl !== null}
      anchorEl={anchorEl}
      transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
      PaperProps={{
        style: {
          transform: `translateY(-9px)`, // Move the menu up
          minWidth: '500px',
          maxHeight: '400px',
        },
      }}
      onClose={onClose}
    >
      {users.map((user) => (
        <MenuItem
          key={user.id}
          onClick={(e) => {
            onClick(user);
            setAnchorEl(null);
          }}
          onKeyDown={(e) => handleKeyDown(e)}
          onKeyUp={(e) => {
            if (e.key === ' ') {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        >
          <ListItemText primary={user.name} />
        </MenuItem>
      ))}
    </Menu>
  );
}

export function WithMentions({
  userDataById,
  setMessageText,
  permissions,
  onAddMentionedUserId,
  message,
  children,
  permissionCheck = 'hasEveryPermission',
}: WithMentionsProps) {
  const locale = useLocale();
  const { t } = useLingui();
  const snackbar = useSnackbar();
  const [mentionText, setMentionText] = useState('');
  const [mentionMode, setMentionMode] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLTextAreaElement | null>(null);
  const [userIdToConfirmPermissionsFor, setUserIdToConfirmPermissionsFor] =
    useState<string | null>(null);
  const [, executeAddPermission] = useAddPermissionMutation();
  const [usersGrantedPermissions, setUsersGrantedPermissions] = useState<
    Array<string>
  >([]);
  const [textBeforeMention, setTextBeforeMention] = useState('');
  const [textAfterMention, setTextAfterMention] = useState('');

  const permissionsOptions = permissions ? [permissions] : [[]];

  const handleConfirmPermissions = async (
    permissions: Array<GQCreatePermissionItemInput & { objectName?: string }>
  ) => {
    invariant(
      userIdToConfirmPermissionsFor,
      'must have one user with insufficient permissions'
    );
    // TODO: Potentially allow people to assign ApproveDatasource permissions here
    const permissionToAssign =
      permissions[0] ??
      permissions.find((p) => p.permission === 'ManageDatasource');

    invariant(permissionToAssign, 'must have one permission to assign');

    const result = await executeAddPermission({
      input: {
        orgAccessId: userDataById[userIdToConfirmPermissionsFor].orgAccessId,
        permission: permissionToAssign.permission,
        objectId: permissionToAssign.objectId,
        objectType: permissionToAssign.objectType,
      },
    });

    void enqueueMutationSnackbar(snackbar, result, {
      onSuccess: () => {
        setUsersGrantedPermissions([
          ...usersGrantedPermissions,
          userIdToConfirmPermissionsFor,
        ]);
        setUserIdToConfirmPermissionsFor(null);
      },
    });
  };

  const handleSelectUserMention = async (
    user: {
      id: string;
      name: string;
    },
    setMessageText: (text: string) => void
  ) => {
    setMentionMode(false);
    const newText =
      textBeforeMention + '@' + user.name + ' ' + textAfterMention;
    const cursorIndex = textBeforeMention.length + user.name.length + 1;
    setMentionText('');
    setMessageText(newText);
    onAddMentionedUserId(user.id);
    setTimeout(() => {
      anchorEl?.setSelectionRange(cursorIndex, cursorIndex);
    }, 0);
  };

  const handleCloseMentionsPopover = async (
    resetText: (value: string) => void,
    value: string
  ) => {
    setMentionMode(false);
    setMentionText('');
    setAnchorEl(null);
    resetText(value);
  };

  const userNameToConfirmPermissionsFor = userIdToConfirmPermissionsFor
    ? userDataById[userIdToConfirmPermissionsFor].name
    : null;

  const objectNames = new Set(permissions?.map((p) => p.objectName));
  UnexpectedError.invariant(
    !permissions || objectNames.size === 1,
    'Expected only one object name for DataIssueDialog permissions'
  );
  const objectName = objectNames.values().next().value;
  const formattedObjectName = formatObjectName(locale, objectName);
  const permissionsText = userNameToConfirmPermissionsFor
    ? objectName === VALUE_MAPPING_OBJECT_NAME
      ? t`${userNameToConfirmPermissionsFor} doesn't have access to this discussion. To add them, you'll need to grant them higher level permissions that enable them to view financial mapping data. Would you like to grant permission to manage measurements?`
      : t`${userNameToConfirmPermissionsFor} doesn't have access to this discussion. To add them, you'll need to grant access to upload and view summaries for the ${formattedObjectName} data source only.`
    : null;

  return (
    <>
      {children({
        value: message,
        setValue: setMessageText,
        onKeyDown: (e) => {
          const target = e.target;
          if (target instanceof HTMLTextAreaElement) {
            if (e.key === '@') {
              const mentionStartIndex = target.selectionStart;
              setMentionMode(true);
              setTextBeforeMention(message.substring(0, mentionStartIndex));
              setTextAfterMention(message.substring(mentionStartIndex));
            }
          }
        },
        onKeyUp: (e) => {
          if (mentionMode) {
            setAnchorEl(e.target as HTMLTextAreaElement);
          }
        },
      })}
      {userIdToConfirmPermissionsFor && (
        <ConfirmPermissionDialog
          onConfirm={async (permissions) => {
            await handleConfirmPermissions(permissions);
            await handleSelectUserMention(
              userDataById[userIdToConfirmPermissionsFor],
              setMessageText
            );
          }}
          onCancel={() => {
            setUserIdToConfirmPermissionsFor(null);
          }}
          permissionsOptions={permissionsOptions}
          shouldShowPermissions={false}
        >
          {permissionsText}
        </ConfirmPermissionDialog>
      )}
      {mentionMode && (
        <MentionsPopover
          // TODO(LOC-31) Should `toLocaleLowerCase` be used?
          users={Object.values(userDataById).filter((user) => {
            return user.name.toLowerCase().includes(mentionText.toLowerCase());
          })}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          onClose={async () => {
            await handleCloseMentionsPopover(
              setMessageText,
              textBeforeMention + '@' + mentionText + textAfterMention
            );
          }}
          onClick={async (user) => {
            if (
              !usersGrantedPermissions.includes(user.id) &&
              !(permissionCheck === 'hasAnyPermission'
                ? userDataById[user.id].hasAnyPermissions
                : userDataById[user.id].hasPermissions)
            ) {
              setUserIdToConfirmPermissionsFor(user.id);
            } else {
              await handleSelectUserMention(user, async (value) =>
                setMessageText(value)
              );
            }
          }}
          handleKeyDown={async (event) => {
            const key = event.key;
            if (key === 'Backspace' && mentionText === '') {
              await handleCloseMentionsPopover(
                (value: string) => setMessageText(value),
                textBeforeMention + textAfterMention
              );
            } else if (
              !event.ctrlKey &&
              !event.altKey &&
              !event.metaKey &&
              key.length === 1
            ) {
              setMessageText(
                textBeforeMention + '@' + mentionText + key + textAfterMention
              );
              setMentionText(mentionText + key);
            } else if (key === 'Backspace') {
              setMessageText(
                textBeforeMention +
                  '@' +
                  mentionText.slice(0, -1) +
                  textAfterMention
              );
              setMentionText(mentionText.slice(0, -1));
            }
          }}
        />
      )}
    </>
  );
}

interface WithMentionsRenderProps {
  value: string;
  setValue: (value: string) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onKeyUp: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

interface WithMentionsProps {
  userDataById: Record<
    string,
    {
      id: string;
      name: string;
      hasPermissions: boolean;
      hasAnyPermissions: boolean;
      orgAccessId: string;
    }
  >;
  setMessageText: (value: string) => void;
  permissions?: Array<GQCreatePermissionItemInput & { objectName?: string }>;
  onAddMentionedUserId: (memberId: string) => void;
  message: string;
  children(props: WithMentionsRenderProps): ReactNode;
  permissionCheck: 'hasAnyPermission' | 'hasEveryPermission';
}

type TextFieldMultilineForMentionsProps = Omit<
  WithMentionsProps,
  'children'
> & {
  rows: number;
  placeholder?: string;
  dataTest: string;
  label?: ReactNode;
};
export function TextFieldMultilineForMentions(
  props: TextFieldMultilineForMentionsProps
) {
  return (
    <WithMentions {...props}>
      {({ onKeyDown, onKeyUp }) => (
        <TextFieldMultiline
          id="message"
          rows={props.rows}
          placeholder={props.placeholder}
          label={props.label}
          dataTest={props.dataTest}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
        />
      )}
    </WithMentions>
  );
}

export function TextFieldMultilineForMentionsNonFormik(
  props: TextFieldMultilineForMentionsProps
) {
  return (
    <WithMentions {...props}>
      {({ onKeyDown, onKeyUp }) => (
        <TextFieldMultilineNonFormik
          value={props.message}
          onChange={(event) => props.setMessageText(event.target.value)}
          id="message"
          rows={props.rows}
          placeholder={props.placeholder}
          label={props.label}
          dataTest={props.dataTest}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
        />
      )}
    </WithMentions>
  );
}
