import { Box, Popover, SxProps, Theme } from '@mui/material';
import ChevronDown from '@watershed/icons/components/ChevronDown';
import { memo, ReactNode, useState, useRef } from 'react';
import {
  mixinSx,
  popoverTransitionDuration,
} from '@watershed/style/styleUtils';
import Button, { ButtonProps } from './Button';
import { focusable } from 'tabbable';
type PopoverButtonAnalyticsEvent = 'open' | 'close';

/**
 * A lower-level component for creating a popover. If you’re looking for a menu,
 * try `PopoverMenu` or `DropdownMenu`, for a radio selection `RadioSelect`,
 * for a checkbox selection `CheckboxSelectNonFormik`. This component uses tabbable library
 * to focus the first focusable element in the popover when it opens for improved
 * accessibility. This is important for keyboard navigation, as it allows users to
 * interact with the popover content without having to manually tab through other
 * elements. It enhances the user experience for those using keyboard navigation
 * or assistive technologies, ensuring a more efficient interaction flow.
 */
export default memo(function PopoverButton({
  children,
  buttonLabel,
  sx,
  paperSx,
  startIcon,
  disabled,
  flat,
  tooltip,
  onClick,
  onClose,
  onAnalyticsEvent = () => {},
  endIcon = <ChevronDown />,
  keepButtonVisible,
  alignRight,
  testId,
  defaultOpen,
  maxHeight,
}: Pick<ButtonProps, 'disabled' | 'startIcon' | 'endIcon' | 'tooltip'> & {
  children: ReactNode | ((closePopover: () => void) => ReactNode);
  buttonLabel?: ReactNode;
  sx?: SxProps<Theme>;
  paperSx?: SxProps<Theme>;
  flat?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  onClose?: () => void;
  onAnalyticsEvent?: (event: PopoverButtonAnalyticsEvent) => void;
  /**
   * By default, the popover takes up the full height of the screen minus some padding
   * (16px). If you want to instead make sure the popover appears below its popover button,
   * set this to true.
   */
  keepButtonVisible?: boolean;
  alignRight?: boolean;
  testId?: string;
  defaultOpen?: boolean;
  maxHeight?: number;
}) {
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(defaultOpen ?? false);
  const popoverContentRef = useRef<HTMLDivElement>(null);

  const onClosePopover = () => {
    setOpen(false);
    if (typeof onClose === 'function') {
      onClose();
    }
  };

  /**
   * Sometimes, we want to make sure our popover doesn't cover the anchor button that opens it.
   * This function returns the min Y value that the popover can be anchored to.
   */
  function getMinYForPopover() {
    return (
      (popoverAnchor?.getBoundingClientRect().bottom ?? 0) +
      16 /* to match the default window padding set by the popover library already */ +
      2 /* for border */
    );
  }

  const horizontalAlignment = alignRight ? 'right' : 'left';

  // Uses tabbable's focusable to focus the first focusable element in the popover when it opens
  // This is important for accessibility and user experience:
  // 1. It ensures keyboard users can immediately interact with the popover content without having to manually tab to it
  // 2. It provides a clear visual indication that the popover has opened and is now the active element
  // 3. It maintains a logical tab order, preventing users from getting lost in the UI
  // 4. It complies with WCAG 2.1 guideline 2.4.3 (Focus Order) by managing focus when new content appears
  const focusFirstFocusableElement = () => {
    requestAnimationFrame(() => {
      if (popoverContentRef.current) {
        const tabbableElements = focusable(popoverContentRef.current);
        if (tabbableElements.length) {
          tabbableElements[0].focus();
        }
      }
    });
  };

  return (
    <>
      <Button
        data-test={testId}
        ref={(ref) => setPopoverAnchor(ref)}
        startIcon={startIcon}
        disabled={disabled}
        flat={flat}
        endIcon={endIcon}
        tooltip={tooltip}
        // Fix for a11y issue where aria-label is passed to wrapping div with tooltip
        aria-label={typeof tooltip === 'string' ? tooltip : undefined}
        onClick={(e) => {
          onAnalyticsEvent('open');
          setOpen(true);
          onClick?.(e);
          focusFirstFocusableElement();
        }}
        className="UIPopoverButton"
        sx={mixinSx({ whiteSpace: 'nowrap' }, sx)}
        aria-haspopup
      >
        {buttonLabel && (
          <Box
            data-test={`${testId}-label`}
            component="span"
            sx={{
              flexGrow: 1,
              textAlign: 'left',
              textOverflow: 'ellipsis',
              width: 'fill-available',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              lineHeight: 1.6,
            }}
          >
            {buttonLabel}
          </Box>
        )}
      </Button>
      <Popover
        anchorEl={popoverAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: horizontalAlignment,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: horizontalAlignment,
        }}
        open={open}
        onClose={() => {
          onAnalyticsEvent('close');
          onClosePopover();
        }}
        transitionDuration={popoverTransitionDuration}
        slotProps={{
          paper: {
            sx: mixinSx(
              keepButtonVisible
                ? {
                    maxHeight: maxHeight
                      ? `calc(min(100vh - ${getMinYForPopover()}px, ${maxHeight}px))`
                      : `calc(100vh - ${getMinYForPopover()}px)`,
                  }
                : undefined,
              paperSx
            ),
          },
        }}
        ref={popoverContentRef}
      >
        {typeof children === 'function' ? children(onClosePopover) : children}
      </Popover>
    </>
  );
});
