import {
  AGE_GROUPS,
  EMPLOYEE_GROUP_TYPE,
  EMPLOYMENT_TYPE,
  GENDER,
  LOBBYING_CONTRIBUTION_KIND,
  LOBBYING_EXPENSE_KIND,
  OWN_WORKFORCE_TYPE,
} from '../batSchemas/constants';
import {
  HEALTH_AND_SAFETY_INCIDENT_TYPES,
  HEALTH_AND_SAFETY_INCIDENTS_EMPLOYEE_TYPES,
} from '../csrd/constants';

export const SUBSTANCE_OF_CONCERN = [
  'substance_of_concern',
  'substance_of_very_high_concern',
  'group_might_contain_substances_of_concern',
  'group_might_contain_substances_of_very_high_concern',
  'not_a_substance_of_concern',
  'unknown',
] as const;

// Reference doc: https://storage.cloud.google.com/watershed-global/cliq/csrd/substances_of_concern/ghs_hazard_statement_code_ec_hazard_class_mapping.xlsx
// We only output a subset of these today.
// TODO(CSRD): Add a TSchema migrator to update the old hazard statements to the new classes.
export const SUBSTANCE_OF_CONCERN_HAZARD_CLASS = [
  'acute_toxicity',
  'aerosol',
  'aspiration_hazard',
  'carcinogenicity',
  'chemical_under_pressure',
  'desensitised_explosives',
  'endocrine_disruptor_for_human_health',
  'endocrine_disruptor_for_the_environment',
  'explosive',
  'flammable_gases',
  'flammable_liquid',
  'flammable_solid',
  'germ_cell_mutagenicity',
  'hazardous_for_the_ozone_layer',
  'hazardous_to_the_aquatic_environment',
  'no_information_found',
  'oxidising_gas',
  'oxidising_liquid',
  'oxidising_solid',
  'persistent_bioaccumulative_and_toxic_very_persistent_and_very_bioaccumulative',
  'persistent_mobile_and_toxic_very_persistent_and_very_mobile',
  'pyrophoric_liquid',
  'pyrophoric_solid',
  'reproductive_toxicity',
  'respiratory_skin_sensitization',
  'self_heating_substance_or_mixture',
  'self_reactive_substance_or_mixture',
  'serious_eye_damage_eye_irritation',
  'skin_corrosion_irritation',
  'specific_target_organ_toxicity_repeated_exposure',
  'specific_target_organ_toxicity_single_exposure',
  'substance_or_mixture_corrosive_to_metals',
  'substance_or_mixture_which_in_contact_with_water_emits_flammable_gas',
] as const;

// Deprecated. We are not removing because this is referenced in BARTs and we cannot delete BARTs. We
// could probably change the BART field to be a string.
// TODO(CSRD): Remove column from BART
export const DEPRECATED__POLLUTANT_MEDIUM = [
  'air_emission',
  'water_emission',
  'soil_emission',
  'used',
  'procured',
  'leaves_in_product',
] as const;

export const SUBSTANCE_AND_MICROPLASTICS_INFLOW_MEDIUM = [
  'procured',
  'generated',
] as const;

export const SUBSTANCE_AND_MICROPLASTICS_INFLOW_MEDIUM_LABELS: Record<
  (typeof SUBSTANCE_AND_MICROPLASTICS_INFLOW_MEDIUM)[number],
  string
> = {
  procured: 'Procured',
  generated: 'Generated',
};

const SUBSTANCE_AND_MICROPLASTICS_OUTFLOW_MEDIUM = [
  'leaves_as_product',
  'leaves_in_product',
] as const;

const SUBSTANCE_AND_MICROPLASTICS_OUTFLOW_MEDIUM_LABELS: Record<
  (typeof SUBSTANCE_AND_MICROPLASTICS_OUTFLOW_MEDIUM)[number],
  string
> = {
  leaves_as_product: 'Leaves as product',
  leaves_in_product: 'Leaves in product',
};

export const SUBSTANCE_OUTFLOW_MEDIUM = [
  ...SUBSTANCE_AND_MICROPLASTICS_OUTFLOW_MEDIUM,
  'air_emission',
  'water_emission',
  'soil_emission',
] as const;

export const SUBSTANCE_OUTFLOW_MEDIUM_LABELS: Record<
  (typeof SUBSTANCE_OUTFLOW_MEDIUM)[number],
  string
> = {
  ...SUBSTANCE_AND_MICROPLASTICS_OUTFLOW_MEDIUM_LABELS,
  air_emission: 'Air emission',
  water_emission: 'Water emission',
  soil_emission: 'Soil emission',
};

export const MICROPLASTICS_OUTFLOW_MEDIUM = [
  ...SUBSTANCE_AND_MICROPLASTICS_OUTFLOW_MEDIUM,
  'emission',
] as const;

export const MICROPLASTICS_OUTFLOW_MEDIUM_LABELS: Record<
  (typeof MICROPLASTICS_OUTFLOW_MEDIUM)[number],
  string
> = {
  ...SUBSTANCE_AND_MICROPLASTICS_OUTFLOW_MEDIUM_LABELS,
  emission: 'Emission',
};

// These come straight from here! https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32006R0166
export const EU_PRTR_CAS_NUMBER_LABELS: Record<string, string> = {
  '74-82-8': 'Methane (74-82-8)',
  '630-08-0': 'Carbon monoxide (630-08-0)',
  '124-38-9': 'Carbon dioxide (124-38-9)',
  '10024-97-2': 'Nitrous oxide (10024-97-2)',
  '7664-41-7': 'Ammonia (7664-41-7)',
  '2551-62-4': 'Sulphur hexafluoride (2551-62-4)',
  '15972-60-8': 'Alachlor (15972-60-8)',
  '309-00-2': 'Aldrin (309-00-2)',
  '1912-24-9': 'Atrazine (1912-24-9)',
  '57-74-9': 'Chlordane (57-74-9)',
  '143-50-0': 'Chlordecone (143-50-0)',
  '470-90-6': 'Chlorfenvinphos (470-90-6)',
  '85535-84-8': 'Chloro-alkanes, C10-C13 (85535-84-8)',
  '2921-88-2': 'Chlorpyrifos (2921-88-2)',
  '50-29-3': 'DDT (50-29-3)',
  '107-06-2': '1,2-dichloroethane (107-06-2)',
  '75-09-2': 'Dichloromethane (75-09-2)',
  '60-57-1': 'Dieldrin (60-57-1)',
  '330-54-1': 'Diuron (330-54-1)',
  '115-29-7': 'Endosulphan (115-29-7)',
  '72-20-8': 'Endrin (72-20-8)',
  '76-44-8': 'Heptachlor (76-44-8)',
  '118-74-1': 'Hexachlorobenzene (118-74-1)',
  '87-68-3': 'Hexachlorobutadiene (87-68-3)',
  '608-73-1': '1,2,3,4,5,6-hexachlorocyclohexane (608-73-1)',
  '58-89-9': 'Lindane (58-89-9)',
  '2385-85-5': 'Mirex (2385-85-5)',
  '608-93-5': 'Pentachlorobenzene (608-93-5)',
  '87-86-5': 'Pentachlorophenol (87-86-5)',
  '1336-36-3': 'Polychlorinated biphenyls (1336-36-3)',
  '122-34-9': 'Simazine (122-34-9)',
  '127-18-4': 'Tetrachloroethylene (127-18-4)',
  '56-23-5': 'Tetrachloromethane (56-23-5)',
  '12002-48-1': 'Trichlorobenzenes (12002-48-1)',
  '71-55-6': '1,1,1-trichloroethane (71-55-6)',
  '79-34-5': '1,1,2,2-tetrachloroethane (79-34-5)',
  '79-01-6': 'Trichloroethylene (79-01-6)',
  '67-66-3': 'Trichloromethane (67-66-3)',
  '8001-35-2': 'Toxaphene (8001-35-2)',
  '75-01-4': 'Vinyl chloride (75-01-4)',
  '120-12-7': 'Anthracene (120-12-7)',
  '71-43-2': 'Benzene (71-43-2)',
  '100-41-4': 'Ethyl benzene (100-41-4)',
  '75-21-8': 'Ethylene oxide (75-21-8)',
  '91-20-3': 'Naphthalene (91-20-3)',
  '117-81-7': 'Di-(2-ethyl hexyl) phthalate (117-81-7)',
  '108-95-2': 'Phenols (108-95-2)',
  '108-88-3': 'Toluene (108-88-3)',
  '1582-09-8': 'Trifluralin (1582-09-8)',
  '1330-20-7': 'Xylenes (1330-20-7)',
  '1332-21-4': 'Asbestos (1332-21-4)',
  '74-90-8': 'Hydrogen cyanide (74-90-8)',
  '1806-26-4': 'Octylphenols and Octylphenol ethoxylates (1806-26-4)',
  '206-44-0': 'Fluoranthene (206-44-0)',
  '465-73-6': 'Isodrin (465-73-6)',
  '36355-1-8': 'Hexabromobiphenyl (36355-1-8)',
  '191-24-2': 'Benzo(g,h,i)perylene (191-24-2)',
  '34123-59-6': 'Isoproturon (34123-59-6)',
};

export const OTHER_CAS_NUMBER_LABELS: Record<string, string> = {
  '75-69-4': 'Trichlorofluoromethane (75-69-4)',
  '75-71-8': 'Dichlorodifluoromethane (75-71-8)',
  '75-72-9': 'Chlorotrifluoromethane (75-72-9)',
  '76-13-1': '1,1,2-trichloro-1,2,2-trifluoroethane (76-13-1)',
  '76-14-2': '1,2-dichloro-1,1,2,2-tetrafluoroethane (76-14-2)',
  '76-15-3': '1-chloro-1,1,2,2,2-pentafluoroethane (76-15-3)',
  '1511-62-2': 'Bromodifluoromethane (1511-62-2)',
  '1842-05-3': '1,1-dichloro-1,2-difluoroethane (1842-05-3)',
  '354-15-4': '1,1,2-trichloro-1,2-difluoroethane (354-15-4)',
  '354-23-4': '1,2-dichloro-1,1,2-trifluoroethane (354-23-4)',
  '75-61-6': 'Dibromodifluoromethane (75-61-6)',
  '353-59-3': 'Bromochlorodifluoromethane (353-59-3)',
  '354-33-6': '1,1,1,2,2-pentafluoroethane (354-33-6)',
  '406-58-6': '1,1,1,3,3-pentafluorobutane (406-58-6)',
  '420-46-2': '1,1,1-trifluoroethane (420-46-2)',
  '431-89-0': '1,1,1,2,3,3,3-heptafluoropropane (431-89-0)',
  '679-86-7': '1,1,2,2,3-pentafluoropropane (679-86-7)',
  '690-39-1': '1,1,1,3,3,3-hexafluoropropane (690-39-1)',
  '75-10-5': 'Difluoromethane (75-10-5)',
  '75-37-6': '1,1-difluoroethane (75-37-6)',
  '75-46-7': 'Trifluoromethane (75-46-7)',
  '811-97-2': '1,1,1,2-tetrafluoroethane (811-97-2)',
  '1717-00-6': '1,1-dichloro-1-fluoroethane (1717-00-6)',
  '2837-89-0': '2-chloro-1,1,1,2-tetrafluoroethane (2837-89-0)',
  '306-83-2': '2,2-dichloro-1,1,1-trifluoroethane (306-83-2)',
  '354-21-2': '1,2,2-trichloro-1,1-difluoroethane (354-21-2)',
  '359-28-4': '1,1,2-Trichloro-2-fluoroethane (359-28-4)',
  '422-56-0': '3,3-dichloro-1,1,1,2,2-pentafluoropropane (422-56-0)',
  '507-55-1': '1,3-dichloro-1,1,2,2,3-pentafluoropropane (507-55-1)',
  '75-43-4': 'Dichlorofluoromethane (75-43-4)',
  '75-45-6': 'Chlorodifluoromethane (75-45-6)',
  '75-68-3': '1-chloro-1,1-difluoroethane (75-68-3)',
  '102687-65-0': '(e)-1-chloro-3,3,3-trifluoroprop-1-ene (102687-65-0)',
  '106-97-8': 'Butane (106-97-8)',
  '109-66-0': 'Pentane (109-66-0)',
  '111512-60-8': '(Z)-1-Chloro-2,3,3,3-tetrafluoropropene (111512-60-8)',
  '115-07-1': 'Propene (115-07-1)',
  '1814-88-6': '1,1,1,2,2-pentafluoropropane (1814-88-6)',
  '2252-84-8': '1,1,1,2,2,3,3-heptafluoropropane (2252-84-8)',
  '24270-66-4': '1,1,2,3,3-pentafluoropropane (24270-66-4)',
  '29118-24-9': '(e)-1,3,3,3-tetrafluoroprop-1-ene (29118-24-9)',
  '29118-25-0': '(z)-1,3,3,3-tetrafluoroprop-1-ene (29118-25-0)',
  '353-36-6': 'Fluoroethane (353-36-6)',
  '360-89-4': 'Octafluoro-2-buene (360-89-4)',
  '375-17-7': '1,1,1,2,2,3,3,4,4-nonafluorobutane (375-17-7)',
  '420-45-1': '2,2-difluoropropane (420-45-1)',
  '421-07-8': '1,1,1-trifluoropropane (421-07-8)',
  '431-31-2': '1,1,1,2,3-pentafluoropropane (431-31-2)',
  '431-63-0': '1,1,1,2,3,3-hexafluoropropane (431-63-0)',
  '460-73-1': '1,1,1,3,3-pentafluoropropane (460-73-1)',
  '5528-43-8': '(z)-1,2,3,3,3-pentafluoroprop-1-ene (5528-43-8)',
  '5595-10-8': '1,2,3,3,3-Pentafluoroprop-1-ene (5595-10-8)',
  '677-21-4': '3,3,3-trifluoroprop-1-ene (677-21-4)',
  '677-56-5': '1,1,1,2,2,3-hexafluoropropane (677-56-5)',
  '692-49-9': '(z)-1,1,1,4,4,4-hexafluorobut-2-ene (692-49-9)',
  '74-84-0': 'Ethane (74-84-0)',
  '74-98-6': 'Propane (74-98-6)',
  '75-28-5': '2-Methylpropane (75-28-5)',
  '75-38-7': '1,1-difluoroethene (75-38-7)',
  '754-12-1': '2,3,3,3-tetrafluoroprop-1-ene (754-12-1)',
  '78-78-4': '2-Methylbutane (78-78-4)',
  '115-25-3': 'Octafluorocyclobutane (115-25-3)',
  '355-25-9': 'Decafluorobutane (355-25-9)',
  '75-73-0': 'Tetrafluoromethane (75-73-0)',
  '76-16-4': 'Hexafluoroethane (76-16-4)',
  '76-19-7': 'Octafluoropropane (76-19-7)',
  '7439-92-1': 'Lead (7439-92-1)',
};

export const CAS_NUMBER_LABELS = {
  ...EU_PRTR_CAS_NUMBER_LABELS,
  ...OTHER_CAS_NUMBER_LABELS,
};

// Reference doc: https://docs.google.com/spreadsheets/d/1wC8L7Oao2ybmHl-AurcQ0g0o7QbOnnxd65Okxag0k8Q/edit#gid=1451937436
// We only output a subset of these in our EM today.
export const EU_POLLUTANT_RELEASE_AND_TRANSFER_REGISTER_POLLUTANT_GROUPS = [
  'non_methane_volatile_organic_compounds',
  'perfluorocarbons',
  'particulate_matter', // TODO: delete this one, replaced with particulate_matter_10
  'particulate_matter_10',
  'particulate_matter_2_5',
  'halons',
  'polycyclic_aromatic_hydrocarbons',
  'sulfur_oxides',
  'hydrochlorofluorocarbons',
  'hydrofluorocarbons',
  'chlorofluorocarbons',
  'chlorides',
  'nitrogen_oxides',
  'copper_and_compounds',
  'lead_and_compounds',
  'chromium_and_compounds',
  'nickel_and_compounds',
  'cadmium_and_compounds',
  'arsenic_and_compounds',
  'mercury_and_compounds',
  'zinc_and_compounds',
  'halogenated_organic_compounds',
  'brominated_diphenylethers',
  'nonylphenol_and_nonylphenol_ethoxylates',
  'organotin_compounds',
  'polychlorinated_biphenyls',
  'total_nitrogen',
  'total_phosphorus',
  'tribytyltin_and_compounds',
  'triphenyltin_and_compounds',
  'total_organic_carbon',
  'cyanides',
  'fluorides',
  'fluorine_and_inorganic_compounds',
  'hydrogen_cyanide',
  'octylphenols_and_octylphenol_ethoxylates',
  'chlorine_and_inorganic_compounds',
] as const;

export const POLLUTANT_GROUP_LABELS: Record<
  (typeof EU_POLLUTANT_RELEASE_AND_TRANSFER_REGISTER_POLLUTANT_GROUPS)[number],
  string
> = {
  non_methane_volatile_organic_compounds:
    'Non methane volatile organic compounds',
  perfluorocarbons: 'Perfluorocarbons',
  particulate_matter: 'Particulate matter',
  particulate_matter_10: 'Particulate matter (PM10)',
  particulate_matter_2_5: 'Particulate matter (PM2.5)',
  polycyclic_aromatic_hydrocarbons: 'Polycyclic aromatic hydrocarbons',
  sulfur_oxides: 'Sulfur oxides',
  halons: 'Halons',
  hydrochlorofluorocarbons: 'Hydrochlorofluorocarbons',
  hydrofluorocarbons: 'Hydrofluorocarbons',
  chlorofluorocarbons: 'Chlorofluorocarbons',
  chlorides: 'Chlorides',
  nitrogen_oxides: 'Nitrogen oxides',
  copper_and_compounds: 'Copper and compounds',
  lead_and_compounds: 'Lead and compounds',
  chromium_and_compounds: 'Chromium and compounds',
  nickel_and_compounds: 'Nickel and compounds',
  cadmium_and_compounds: 'Cadmium and compounds',
  arsenic_and_compounds: 'Arsenic and compounds',
  mercury_and_compounds: 'Mercury and compounds',
  zinc_and_compounds: 'Zinc and compounds',
  halogenated_organic_compounds: 'Halogenated organic compounds',
  brominated_diphenylethers: 'Brominated diphenylethers',
  nonylphenol_and_nonylphenol_ethoxylates:
    'Nonylphenol and Nonylphenol ethoxylates',
  organotin_compounds: 'Organotin compounds',
  polychlorinated_biphenyls: 'Polychlorinated biphenyls',
  total_nitrogen: 'Total nitrogen',
  total_phosphorus: 'Total phosphorus',
  tribytyltin_and_compounds: 'Tributyltin and compounds',
  triphenyltin_and_compounds: 'Triphenyltin and compounds',
  total_organic_carbon: 'Total organic carbon',
  cyanides: 'Cyanides',
  fluorides: 'Fluorides',
  fluorine_and_inorganic_compounds: 'Fluorine and inorganic compounds',
  hydrogen_cyanide: 'Hydrogen cyanide',
  octylphenols_and_octylphenol_ethoxylates:
    'Octylphenols and Octylphenol ethoxylates',
  chlorine_and_inorganic_compounds: 'Chlorine and inorganic compounds',
};

export const SUBSTANCE_OF_CONCERN_LABELS: Record<
  (typeof SUBSTANCE_OF_CONCERN)[number],
  string
> = {
  substance_of_concern: 'Substance of concern',
  not_a_substance_of_concern: 'Not a substance of concern',
  group_might_contain_substances_of_concern:
    'Group might contain substances of concern',
  group_might_contain_substances_of_very_high_concern:
    'Group might contain substances of very high concern',
  substance_of_very_high_concern: 'Substance of very high concern',
  unknown: 'Unknown',
};

// Reference doc: https://docs.google.com/spreadsheets/d/1wC8L7Oao2ybmHl-AurcQ0g0o7QbOnnxd65Okxag0k8Q/edit#gid=1597682651
// We only output a subset of these in our EM today.
export const CAS_NUMBER = [
  '630-08-0',
  '7664-41-7',
  '7439-92-1',
  '10024-97-2',
  '75-10-5',
  '354-33-6',
  '75-45-6',
  '67-66-3',
  '71-43-2',
] as const;

export const ENERGY_KIND_VALUES = ['cooling', 'electricity', 'heat', 'steam'];

export const HEATING_VALUE_KIND_VALUES = ['HHV', 'LHV'];

export const EMPLOYEE_GROUP_TYPE_LABELS: Record<
  (typeof EMPLOYEE_GROUP_TYPE)[number],
  string
> = {
  permanent: 'Permanent',
  temporary: 'Temporary',
};

export const EMPLOYMENT_TYPE_LABELS: Record<
  (typeof EMPLOYMENT_TYPE)[number],
  string
> = {
  full_time: 'Full-time',
  part_time: 'Part-time',
  employee_with_non_guaranteed_hours: 'Non-guaranteed hours',
};

export const GENDER_LABELS: Record<(typeof GENDER)[number], string> = {
  male: 'Male',
  female: 'Female',
  unknown: 'Unknown',
  other: 'Other',
};

export const OWN_WORKFORCE_TYPE_LABELS: Record<
  (typeof OWN_WORKFORCE_TYPE)[number],
  string
> = {
  employees: 'Employees',
  non_employees: 'Non-employees',
};

export const AGE_GROUP_LABELS: Record<(typeof AGE_GROUPS)[number], string> = {
  under_30: 'Under 30',
  '30_50': '30-50',
  over_50: 'Over 50',
};

export const LOBBYING_CONTRIBUTION_KIND_LABEL_MAPPING: Record<
  (typeof LOBBYING_CONTRIBUTION_KIND)[number],
  string
> = {
  in_kind: 'In-kind',
  financial: 'Financial',
};

export const LOBBYING_EXPENSE_KIND_LABEL_MAPPING: Record<
  (typeof LOBBYING_EXPENSE_KIND)[number],
  string
> = {
  direct_political_expense: 'Direct political expense',
  internal_or_external_lobbying_expense:
    'Internal or external lobbying expense',
  lobbying_membership_expense: 'Lobbying membership expense',
};

export const INCIDENT_TYPE_LABEL_MAPPING: Record<
  (typeof HEALTH_AND_SAFETY_INCIDENT_TYPES)[number],
  string
> = { ill_health: 'Ill health', injury: 'Injury' };

export const HEALTH_AND_SAFETY_INCIDENT_EMPLOYEE_TYPE_LABELS: Record<
  (typeof HEALTH_AND_SAFETY_INCIDENTS_EMPLOYEE_TYPES)[number],
  string
> = {
  employee: 'Employee',
  non_employee: 'Non-employee',
  value_chain_on_site: 'Value chain on site',
};
