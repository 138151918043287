/* @skip-file-for-translation */
import must from './must';
import { plural, t } from '@lingui/core/macro';
import {
  GQPermissionType,
  GQUserTablePermissionsFragment,
} from '../generated/graphql';
import { joinWithAnd, smartLowerCase, humanize } from './helpers';
import mapStrToEnum from './mapStrToEnum';
import groupBy from 'lodash/groupBy';
import assertNever from './assertNever';

import { SupportedLocale } from '@watershed/intl/constants';
import isNotNullish from './isNotNullish';
import { formatList } from '@watershed/intl/formatters';

export function getPermissionTypeDisplayName(
  permission: GQPermissionType
): string {
  switch (permission) {
    case GQPermissionType.ManageDataset:
      return t`Manage datasets`;
    case GQPermissionType.ManageDatasource:
      return t`Manage data sources`;
    case GQPermissionType.ApproveDatasource:
      return t`Approve data sources`;
    case GQPermissionType.ManageCompanyTags:
      return t`Manage company tags`;
    case GQPermissionType.ManageOrgHierarchy:
      return t`Manage organizational hierarchy`;
    case GQPermissionType.Admin:
      return t`Admin`;
    case GQPermissionType.ManageMarketplacePurchases:
      return t`Manage Marketplace purchases`;
    case GQPermissionType.ManageMeasurement:
      return t`Manage measurement`;
    case GQPermissionType.ManageReductionPlans:
      return t`Manage reduction plans`;
    case GQPermissionType.ManageSingleSignOn:
      return t`Manage single sign on`;
    case GQPermissionType.ManageSuppliers:
      return t`Manage suppliers`;
    case GQPermissionType.ManageDisclosures:
      return t`Manage disclosures`;
    case GQPermissionType.ViewEmployeeReport:
      return t`View summary report`;
    case GQPermissionType.ViewFootprintDetail:
      return t`View footprint detail`;
    case GQPermissionType.ViewReductions:
      return t`View reductions`;
    default:
      return humanize(permission) || t`Unknown`;
  }
}

export function getPermissionDisplayName(
  permission: GQPermissionType,
  objectIds: Array<string | null> = [],
  objectName?: string,
  locale?: SupportedLocale
): string {
  const nonNullCount = objectIds.filter(Boolean).length;
  switch (permission) {
    case GQPermissionType.ManageDataset:
      if (nonNullCount === 0 || objectIds.includes(null)) {
        return t`Manage all datasets`;
      } else if (nonNullCount === 1 && objectName) {
        const permissionName = smartLowerCase(objectName, { locale });
        return t`Manage ${permissionName} dataset`;
      } else {
        const numDatasets = objectIds.length;
        return plural(numDatasets, {
          one: 'Manage # dataset',
          other: 'Manage # datasets',
        });
      }

    case GQPermissionType.ManageDatasource:
      if (nonNullCount === 0 || objectIds.includes(null)) {
        return t`Manage all data sources`;
      } else if (nonNullCount === 1 && objectName) {
        const permissionName = smartLowerCase(objectName, { locale });
        return t`Manage ${permissionName} data source`;
      } else {
        const numDatasources = objectIds.length;
        return plural(numDatasources, {
          one: 'Manage # data source',
          other: 'Manage # data sources',
        });
      }

    case GQPermissionType.ApproveDatasource:
      if (nonNullCount === 0 || objectIds.includes(null)) {
        return t`Approve all data sources`;
      } else if (nonNullCount === 1 && objectName) {
        return t`Approve ${objectName} data source`;
      } else {
        const numDatasources = objectIds.length;
        return plural(numDatasources, {
          one: 'Approve # data source',
          other: 'Approve # data sources',
        });
      }

    case GQPermissionType.ManageSuppliers:
      if (nonNullCount === 0 || objectIds.includes(null)) {
        return t`Manage all suppliers`;
      } else if (nonNullCount === 1 && objectName) {
        return t`Manage supplier: ${objectName}`;
      } else {
        const numSuppliers = objectIds.length;
        return plural(numSuppliers, {
          one: 'Manage # supplier',
          other: 'Manage # suppliers',
        });
      }
    default:
      return getPermissionTypeDisplayName(permission);
  }
}

export function getPermissionDescription(
  permission?: GQPermissionType | null
): string | undefined {
  switch (permission) {
    case GQPermissionType.ManageDataset:
      return t`Allows viewing summaries and uploading files for selected datasets`;
    case GQPermissionType.ManageDatasource:
      return t`Allows viewing summaries and uploading files for selected data sources`;
    case GQPermissionType.ApproveDatasource:
      return t`Allows viewing and approving data sources`;
    case GQPermissionType.ManageCompanyTags:
      return t`Allows viewing and approving your company tags`;
    case GQPermissionType.ManageOrgHierarchy:
      return t`Allows viewing and approving your organizational hierarchy`;
    case GQPermissionType.Admin:
      return t`Full access to the dashboard, including inviting new collaborators`;
    case GQPermissionType.CorporateAdmin:
      return t`Full access to the Corporate product, including inviting new collaborators`;
    case GQPermissionType.ManageMarketplacePurchases:
      return t`View and purchase offsets on the Marketplace`;
    case GQPermissionType.ManageMeasurement:
      return t`View and manage footprint measurement`;
    case GQPermissionType.ManageReductionPlans:
      return t`View and update reduction plans`;
    case GQPermissionType.ManageSingleSignOn:
      return t`Manage single sign on for the org`;
    case GQPermissionType.ManageSuppliers:
      return t`View and update suppliers`;
    case GQPermissionType.ManageDisclosures:
      return t`View and respond to disclosure requests (surveys) from customers`;
    case GQPermissionType.ViewEmployeeReport:
      return t`View the summary report if one exists`;
    case GQPermissionType.ViewFootprintDetail:
      return t`View the full footprint and insights`;
    case GQPermissionType.ViewAuditDetail:
      return t`View details about calculations and data (for e.g., auditors and verifiers)`;
    case GQPermissionType.ViewReductions:
      return t`View reduction plans`;
    case GQPermissionType.WatershedAdmin:
      return t`Superadmin permission for Watershed employees only`;
    case GQPermissionType.FinanceAdmin:
      return t`Full access to the Finance product, including inviting new collaborators`;
    case GQPermissionType.ManageFund:
      return t`View and manage fund-level data and assets`;
    case GQPermissionType.FinanceReadOnly:
      return t`View-only access to the Finance product`;
    case GQPermissionType.ViewLearningHub:
      return t`View the Learning Hub (already accessible with any other permission)`;
    case GQPermissionType.AnyUser:
      return t`Permission that all users have`;
    case null:
    case undefined:
      return undefined;
    default:
      assertNever(permission);
  }
}

export function getPermissionGroupDescription(
  permission: GQPermissionType,
  objectNames: Array<string | undefined>,
  locale: SupportedLocale
): string | undefined {
  const names = objectNames.filter(isNotNullish);
  const listOfNames = smartLowerCase(
    formatList(names, {
      type: 'conjunction',
      style: 'long',
      locale,
    }),
    { locale }
  );
  const objectNamesLength = objectNames.length;

  switch (permission) {
    case GQPermissionType.ManageDataset:
      return objectNamesLength === 0 || objectNames.includes(undefined)
        ? t`Upload and view all dataset summaries`
        : plural(objectNamesLength, {
            one: `Upload and view summary for the ${listOfNames} dataset`,
            other: `Upload and view summaries for the ${listOfNames} datasets`,
          });

    case GQPermissionType.ManageDatasource:
      return objectNamesLength === 0 || objectNames.includes(undefined)
        ? t`Upload and view all data source summaries`
        : plural(objectNamesLength, {
            one: `Upload and view summaries for the ${listOfNames} data source`,
            other: `Upload and view summaries for the ${listOfNames} data sources`,
          });

    case GQPermissionType.ApproveDatasource:
      return objectNamesLength === 0 || objectNames.includes(undefined)
        ? t`View and approve all data sources`
        : plural(objectNamesLength, {
            one: `View and approve summaries for the ${listOfNames} data source`,
            other: `View and approve summaries for the ${listOfNames} data sources`,
          });

    case GQPermissionType.ManageSuppliers:
      return objectNamesLength === 0 || objectNames.includes(undefined)
        ? t`View and update all suppliers`
        : t`View and update these suppliers: ${listOfNames}`;
    default:
      return getPermissionDescription(permission);
  }
}

export function getRolesAndPermissionsList(
  roles: Readonly<GQUserTablePermissionsFragment['roles']>,
  permissions: Readonly<GQUserTablePermissionsFragment['permissions']>
): [Array<string>, Array<string>] {
  const roleNames = roles
    .filter(({ revokedAt, role }) => !revokedAt && role)
    .map(({ role }) => must(role).name);
  const permissionNames = Object.entries(
    groupBy(
      permissions.filter(({ revokedAt }) => !revokedAt),
      (p) => p.permission
    )
  ).map(([permission, entries]) =>
    getPermissionDisplayName(
      mapStrToEnum(permission, GQPermissionType),
      entries.map((e) => e.objectId)
    )
  );
  return [roleNames, permissionNames.reverse()];
}

export function getRolesAndPermissionsSummary(
  roles: Readonly<GQUserTablePermissionsFragment['roles']>,
  permissions: Readonly<GQUserTablePermissionsFragment['permissions']>
): string {
  return humanize(
    joinWithAnd(getRolesAndPermissionsList(roles, permissions).flat())
  );
}
