// Questions, serializers, and validators for supplier engagement surveys
import * as Yup from 'yup';
import {
  GQCompanyClimateCommitmentKind,
  GQCompanySurveyQuestionKey as QuestionKey,
  GQEngagementTaskContainerComponentId as ContainerComponentId,
  GQEngagementTaskQuestionComponentId as QuestionComponentId,
  GQDisclosureInitiativeType,
  GQDisclosureTargetIntensityType,
  GQSasbSectorType,
  GQSasbIndustryType,
  GQEsgdcGhgMethodologyType,
  GQDisclosureTargetReductionRate,
  GQCompanySbtCommitmentStage,
  GQEdciScope1MethodologyOption,
  GQEdciScope2MethodologyOption,
  GQTaskWatershedProcessStateSimplified,
  GQWatershedFootprintReviewRequestStatus,
  GQSupplierTableColumnFormat,
} from '../generated/graphql';

import {
  ExpenseNaics,
  MaterialIssue,
  ReductionInitiatives,
  ReductionInitiativesType,
  ReductionTargets,
  SurveySitesElectricityData,
  TargetScope,
  SurveyAttachmentAnswer,
  AttachmentSchema,
  CarbonMeasurementPlan,
  YesNoMaybe,
  YesNo,
  AllocateEmissionsMethod,
  ShareMethodV2,
  RenewableEnergyUsage,
  AcceptDeclineModify,
  CeeCloudItem,
  CeeBuildingItem,
  CeeWoodItem,
  ExternalReportingType,
  SustainabilityCommitment,
  TmsManualInput,
  SustainabilityTeamSize,
  FacilityEnergySources,
  FacilityWaterSources,
  GHG_SCOPE_SCHEMA,
  ExpenseCategoryEmissionsSchema,
  HaveMeasuredEmissionsV2,
  AllocateEmissionsMethodV2,
  SustainabilityInitiatives,
  SustainabilityInitiativesType,
  ExpenseCategoryShareMethodOption,
  HeatingType,
} from './types';

import { YM } from '../utils/YearMonth';
import surveyExpenseCodeMapping from '../generated/shared_data/naics_survey_mapping.json';
import toPairs from 'lodash/toPairs';
import fromPairs from 'lodash/fromPairs';
import { CURRENCY_MAP } from '../utils/currencies';
import { COUNTRIES_CONST } from '../countryConstants';
import {
  COMPANY_GROWTH_STAGES,
  DECARBONIZATION_PLAN_SELECT_OPTIONS,
  ENGAGEMENT_TASK_LONG_TERM_NET_ZERO_SELECT_OPTIONS,
  ENGAGEMENT_TASK_SHORT_TERM_REDUCTION_TARGETS_SELECT_OPTIONS,
  GHG_METHODOLOGY_TYPES,
} from '../suppliers/SupplierSurveySelectOptions';
import assertNever from '../utils/assertNever';
import { BadDataError } from '../errors/BadDataError';

export type QuestionKeyExtendedType = QuestionKey | string;

export { QuestionKey, ContainerComponentId, QuestionComponentId };

type SurveyExpenseCodeMapping = typeof surveyExpenseCodeMapping;

export const MIN_YEAR = 2010;
export const DEFAULT_BASELINE_YEAR = 2021;
export const DEFAULT_END_YEAR = 2030;
export const MAX_YEAR = 2060;

export const DEFAULT_NEW_TARGET = {
  baselineYear: DEFAULT_BASELINE_YEAR,
  baselineYearEmissions: {
    scope1TonnesCo2e: null,
    scope2TonnesCo2e: null,
    scope3TonnesCo2e: null,
    scope3ByCategoryTonnesCo2e: {},
  },
  type: GQDisclosureTargetIntensityType.Absolute,
  targetScope: TargetScope.Scope1and2and3,
  targetPercentage: null,
  targetYear: DEFAULT_END_YEAR,
  unit: null,
  unitDescription: null,
};

export const DEFAULT_NEW_TARGET_NON_NULL = {
  baselineYear: DEFAULT_BASELINE_YEAR,
  baselineYearEmissions: {
    scope1TonnesCo2e: 0,
    scope2TonnesCo2e: 0,
    scope3TonnesCo2e: 0,
    scope3ByCategoryTonnesCo2e: {},
  },
  reductionRate: GQDisclosureTargetReductionRate.LinearAnnualReduction,
  type: GQDisclosureTargetIntensityType.Absolute,
  targetScope: TargetScope.Scope1and2and3,
  targetPercentage: 0,
  targetYear: DEFAULT_END_YEAR,
  unit: null,
  unitDescription: null,
};

export const DEFAULT_NEW_INITIATIVE = {
  name: null,
  description: null,
  baselineYear: DEFAULT_BASELINE_YEAR,
  baselineYearEmissions: {
    scope1TonnesCo2e: 0,
    scope2TonnesCo2e: 0,
    scope3TonnesCo2e: 0,
    scope3ByCategoryTonnesCo2e: {},
  },
  endYear: DEFAULT_END_YEAR,
  type: GQDisclosureInitiativeType.RenewableEnergy,
  initiativeScope: null,
  expectedReductionTonnesCo2e: null,
  reductionPercentage: null,
  reductionType: null,
};

export const DEFAULT_NEW_CEE_BUILDING_ITEM = {
  size: null,
  location: null,
  heating: HeatingType.NaturalGasFurnace,
};

export const DEFAULT_NEW_CEE_WOOD_ITEM = {
  country: null,
  woodCubeMeters: null,
};

export const DEFAULT_NEW_CEE_CLOUD_ITEM = {
  provider: null,
  percent: null,
  annualSpend: null,
  annualSpendCurrency: null,
};

export const DEFAULT_NEW_CEE_TMS_MANUAL_INPUT = {
  transportationMethod: 'Truck',
  vehicleType: 'Standard',
  tonnes: 1000,
  kilometers: 1000,
  fuelType: undefined,
  fuelUsed: undefined,
};

const NUMBER_FIELD_OPTIONAL_SCHEMA = Yup.object({
  value: Yup.number().when(['unknown'], {
    is: (unknown: boolean) => !unknown,
    then: Yup.number().required(),
    otherwise: Yup.number().nullable(),
  }),
  unknown: Yup.boolean().required(),
}).nullable();

export const DEFAULT_NEW_SUSTAINABILITY_COMMITMENT: SustainabilityCommitment = {
  kind: GQCompanyClimateCommitmentKind.NetZero,
  targetYear: 2030,
};

export const COMMITMENT_KIND_TO_LABEL = {
  [GQCompanyClimateCommitmentKind.CarbonNeutral]: 'Carbon neutral',
  [GQCompanyClimateCommitmentKind.CleanEnergy]: 'Clean energy',
  [GQCompanyClimateCommitmentKind.ScienceBasedTarget]: 'Science-based target',
  [GQCompanyClimateCommitmentKind.NetZero]: 'Net-zero',
};

const VALID_NUMBER_MESSAGE = 'Please enter a valid number';

// TODO (SUP-7870) Internationalize validation messages in yup schemas
// Validators, default values, and the survey step for each question. Note that
// these are used to infer TypeScript types for SurveyFormValues. When you
// update this structure, make sure the inferred types are still correct.
export const QUESTION_SCHEMAS = {
  [QuestionKey.CompanyName]: {
    key: QuestionKey.CompanyName,
    defaultValue: '',
    isPrivateToSupplier: false,
    schema: Yup.string()
      .required('Please enter a company name.')
      .max(1000, 'Company name must be less than 1,000 characters.'),
  },

  [QuestionKey.CompanyIndustry]: {
    key: QuestionKey.CompanyIndustry,
    defaultValue: '',
    isPrivateToSupplier: false,
    schema: Yup.string().required('Please select an industry.'),
  },

  [QuestionKey.SasbSector]: {
    key: QuestionKey.SasbSector,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<GQSasbSectorType>().oneOf([
      null,
      ...Object.values(GQSasbSectorType),
    ]),
  },

  [QuestionKey.SasbIndustry]: {
    key: QuestionKey.SasbIndustry,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<GQSasbIndustryType>().oneOf([
      null,
      ...Object.values(GQSasbIndustryType),
    ]),
  },

  [QuestionKey.CountryOfHeadquarters]: {
    key: QuestionKey.CountryOfHeadquarters,
    defaultValue: '',
    isPrivateToSupplier: false,
    schema: Yup.string().required(
      'Please enter the country of your headquarters.'
    ),
  },

  [QuestionKey.CountriesOfOperation]: {
    key: QuestionKey.CountriesOfOperation,
    defaultValue: [],
    isPrivateToSupplier: false,
    schema: Yup.array(Yup.string())
      .required('Please enter your countries of operation.')
      .min(1, 'Please enter at least one country of operation.'),
  },

  [QuestionKey.Headcount]: {
    key: QuestionKey.Headcount,
    defaultValue: 0,
    isPrivateToSupplier: false,
    schema: Yup.number()
      .required('Please enter a number.')
      .min(0, 'Please enter a positive number.')
      .max(10_000_000, 'Please enter a number less than 10,000,000.'),
  },

  [QuestionKey.AllocationMethodology]: {
    key: QuestionKey.AllocationMethodology,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().optional(),
  },

  [QuestionKey.ImportReport]: {
    key: QuestionKey.ImportReport,
    defaultValue: [],
    isPrivateToSupplier: false,
    schema: Yup.array(AttachmentSchema).optional(),
  },

  [QuestionKey.GhgScope1TonnesCo2e]: {
    key: QuestionKey.GhgScope1TonnesCo2e,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: GHG_SCOPE_SCHEMA,
  },

  [QuestionKey.GhgScope1Methodology]: {
    key: QuestionKey.GhgScope1Methodology,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<GQEsgdcGhgMethodologyType>().oneOf([
      null,
      ...Object.values(GQEsgdcGhgMethodologyType),
    ]),
  },

  [QuestionKey.GhgScope2LocationBasedTonnesCo2e]: {
    key: QuestionKey.GhgScope2LocationBasedTonnesCo2e,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: GHG_SCOPE_SCHEMA,
  },

  [QuestionKey.GhgScope2MarketBasedTonnesCo2e]: {
    key: QuestionKey.GhgScope2MarketBasedTonnesCo2e,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: GHG_SCOPE_SCHEMA,
  },

  [QuestionKey.GhgScope1RevenueIntensity]: {
    key: QuestionKey.GhgScope1RevenueIntensity,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number()
      .nullable()
      .defined()
      .min(0, 'Please enter a positive number.'),
  },

  [QuestionKey.GhgScope2LocationBasedRevenueIntensity]: {
    key: QuestionKey.GhgScope2LocationBasedRevenueIntensity,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number()
      .nullable()
      .defined()
      .min(0, 'Please enter a positive number.'),
  },

  [QuestionKey.GhgScope2MarketBasedRevenueIntensity]: {
    key: QuestionKey.GhgScope2MarketBasedRevenueIntensity,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number()
      .nullable()
      .defined()
      .min(0, 'Please enter a positive number.'),
  },

  [QuestionKey.ShareMethodV2]: {
    key: QuestionKey.ShareMethodV2,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<ShareMethodV2>().oneOf(Object.values(ShareMethodV2)),
  },

  [QuestionKey.ExpenseCategoryShareMethod]: {
    key: QuestionKey.ExpenseCategoryShareMethod,
    defaultValue: {},
    isPrivateToSupplier: false,
    schema: Yup.lazy((value) =>
      Yup.object(
        fromPairs(
          Object.keys(value).map((k) => [
            k,
            Yup.mixed<ExpenseCategoryShareMethodOption>().oneOf(
              Object.values(ExpenseCategoryShareMethodOption)
            ),
          ])
        )
      )
    ),
  },

  [QuestionKey.ExpenseCategoryEmissions]: {
    key: QuestionKey.ExpenseCategoryEmissions,
    defaultValue: {},
    isPrivateToSupplier: false,
    schema: Yup.lazy((value) =>
      Yup.object(
        fromPairs(
          Object.keys(value).map((k) => [k, ExpenseCategoryEmissionsSchema])
        )
      )
    ),
  },

  [QuestionKey.PleeEstimationOutputs]: {
    key: QuestionKey.PleeEstimationOutputs,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.lazy(() => Yup.mixed()),
  },

  [QuestionKey.GhgScope3ByCategoryTonnesCo2e]: {
    key: QuestionKey.GhgScope3ByCategoryTonnesCo2e,
    defaultValue: {},
    isPrivateToSupplier: false,
    schema: Yup.lazy(() => Yup.mixed()),
  },

  [QuestionKey.GhgScope3Methodology]: {
    key: QuestionKey.GhgScope3Methodology,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<GQEsgdcGhgMethodologyType>().oneOf([
      null,
      ...Object.values(GQEsgdcGhgMethodologyType),
    ]),
  },

  [QuestionKey.GhgScope3ByCategoryRevenueIntensity]: {
    key: QuestionKey.GhgScope3ByCategoryRevenueIntensity,
    defaultValue: {},
    isPrivateToSupplier: false,
    schema: Yup.lazy(() => Yup.mixed()),
  },

  [QuestionKey.GhgScope3TotalTonnesCo2e]: {
    key: QuestionKey.GhgScope3TotalTonnesCo2e,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: GHG_SCOPE_SCHEMA,
  },
  [QuestionKey.GhgScope3TotalRevenueIntensity]: {
    key: QuestionKey.GhgScope3TotalRevenueIntensity,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: GHG_SCOPE_SCHEMA,
  },
  [QuestionKey.CustomerSpendInReportingYear]: {
    key: QuestionKey.CustomerSpendInReportingYear,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number(),
  },

  [QuestionKey.HaveMeasuredEmissions]: {
    key: QuestionKey.HaveMeasuredEmissions,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNo>().oneOf(Object.values(YesNo)),
  },

  [QuestionKey.HaveMeasuredEmissionsV2]: {
    key: QuestionKey.HaveMeasuredEmissionsV2,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<HaveMeasuredEmissionsV2>().oneOf([
      null,
      ...Object.values(HaveMeasuredEmissionsV2),
    ]),
  },

  [QuestionKey.AllocateEmissionsMethodV2]: {
    key: QuestionKey.AllocateEmissionsMethodV2,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<AllocateEmissionsMethodV2>().oneOf([
      null,
      ...Object.values(AllocateEmissionsMethodV2),
    ]),
  },

  [QuestionKey.ShareEmissionsPreference]: {
    key: QuestionKey.HaveMeasuredEmissions,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNo>().oneOf(Object.values(YesNo)),
  },

  [QuestionKey.AllocateEmissionsMethod]: {
    key: QuestionKey.AllocateEmissionsMethod,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<AllocateEmissionsMethod>().oneOf(
      Object.values(AllocateEmissionsMethod)
    ),
  },

  [QuestionKey.Revenue]: {
    key: QuestionKey.Revenue,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable().min(0, 'Please enter a positive number.'),
  },

  [QuestionKey.RevenueCurrency]: {
    key: QuestionKey.RevenueCurrency,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string()
      .nullable()
      .max(1000, 'Your revenue currency must be less than 1,000 characters.'),
  },

  [QuestionKey.CleanPowerDescription]: {
    key: QuestionKey.CleanPowerDescription,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().nullable(),
  },

  [QuestionKey.CleanPowerPercentage]: {
    key: QuestionKey.CleanPowerPercentage,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number()
      .nullable()
      .min(0, 'Please enter a positive number.')
      .max(100, 'Please enter a number less than 100.'),
  },
  [QuestionKey.CleanPowerPurchased]: {
    key: QuestionKey.CleanPowerPurchased,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNoMaybe>()
      .nullable()
      .oneOf([null, ...Object.values(YesNoMaybe)]),
  },

  [QuestionKey.CleanPowerImportReport]: {
    key: QuestionKey.CleanPowerImportReport,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.array(AttachmentSchema).nullable(),
  },
  [QuestionKey.ProviderVerifiedFootprint]: {
    key: QuestionKey.ProviderVerifiedFootprint,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNoMaybe>()
      .nullable()
      .oneOf([null, ...Object.values(YesNoMaybe)]),
  },
  [QuestionKey.FootprintProviderName]: {
    key: QuestionKey.FootprintProviderName,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().nullable(),
  },
  [QuestionKey.FootprintMethodologyImportReport]: {
    key: QuestionKey.FootprintMethodologyImportReport,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.array(AttachmentSchema).nullable(),
  },

  [QuestionKey.CarbonMeasurementPlan]: {
    key: QuestionKey.CarbonMeasurementPlan,
    defaultValue: CarbonMeasurementPlan.NoPlan,
    isPrivateToSupplier: false,
    schema: Yup.mixed<keyof typeof CarbonMeasurementPlan>()
      .required('Please choose your plan for measuring your carbon emissions.')
      .oneOf(Object.values(CarbonMeasurementPlan)),
  },

  [QuestionKey.CarbonMeasurementCompletionDate]: {
    key: QuestionKey.CarbonMeasurementCompletionDate,
    defaultValue: YM.now(),
    isPrivateToSupplier: false,
    schema: Yup.number().required(),
  },

  [QuestionKey.CarbonMeasurementProvider]: {
    key: QuestionKey.CarbonMeasurementProvider,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().nullable(),
  },
  [QuestionKey.Scope1And2EmissionTargets]: {
    key: QuestionKey.Scope1And2EmissionTargets,
    defaultValue: '',
    isPrivateToSupplier: false,
    schema: Yup.string().nullable(),
  },

  [QuestionKey.Scope3EmissionTargets]: {
    key: QuestionKey.Scope3EmissionTargets,
    defaultValue: '',
    isPrivateToSupplier: false,
    schema: Yup.string().nullable(),
  },
  [QuestionKey.GhgScope1StationaryCombustionTonnesCo2e]: {
    key: QuestionKey.GhgScope1StationaryCombustionTonnesCo2e,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.GhgScope1StationaryCombustionRevenueIntensity]: {
    key: QuestionKey.GhgScope1StationaryCombustionRevenueIntensity,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.GhgScope1MobileCombustionTonnesCo2e]: {
    key: QuestionKey.GhgScope1MobileCombustionTonnesCo2e,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.GhgScope1MobileCombustionRevenueIntensity]: {
    key: QuestionKey.GhgScope1MobileCombustionRevenueIntensity,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.GhgScope1FugitiveEmissionsTonnesCo2e]: {
    key: QuestionKey.GhgScope1FugitiveEmissionsTonnesCo2e,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.GhgScope1FugitiveEmissionsRevenueIntensity]: {
    key: QuestionKey.GhgScope1FugitiveEmissionsRevenueIntensity,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.GhgScope2DisclosureIncludesLeasedBuildings]: {
    key: QuestionKey.GhgScope2DisclosureIncludesLeasedBuildings,
    defaultValue: YesNoMaybe.Yes,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNoMaybe>()
      .nullable()
      .oneOf([null, ...Object.values(YesNoMaybe)]),
  },
  [QuestionKey.ProcessCheckEmissionsAlreadyAllocated]: {
    key: QuestionKey.ProcessCheckEmissionsAlreadyAllocated,
    defaultValue: YesNoMaybe.No,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNoMaybe>()
      .nullable()
      .oneOf([null, ...Object.values(YesNoMaybe)]),
  },
  [QuestionKey.GhgScope1CustomerShareOfEmissionsTonnesCo2e]: {
    key: QuestionKey.GhgScope1CustomerShareOfEmissionsTonnesCo2e,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.GhgScope2CustomerShareOfEmissionsTonnesCo2e]: {
    key: QuestionKey.GhgScope2CustomerShareOfEmissionsTonnesCo2e,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.GhgScope3CustomerShareOfEmissionsTonnesCo2e]: {
    key: QuestionKey.GhgScope3CustomerShareOfEmissionsTonnesCo2e,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.SitesElectricityData]: {
    key: QuestionKey.SitesElectricityData,
    defaultValue: [],
    isPrivateToSupplier: false,
    schema: Yup.array(SurveySitesElectricityData).nullable(),
  },
  [QuestionKey.RemainingSitesElectricityMWh]: {
    key: QuestionKey.RemainingSitesElectricityMWh,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.EnergyUseEstimateMethod]: {
    key: QuestionKey.EnergyUseEstimateMethod,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().nullable(),
  },
  [QuestionKey.YearFullyRenewableForCustomer]: {
    key: QuestionKey.YearFullyRenewableForCustomer,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.ChallengesProcuringRenewableForCustomer]: {
    key: QuestionKey.ChallengesProcuringRenewableForCustomer,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().nullable(),
  },
  [QuestionKey.ReductionTargetsSet]: {
    key: QuestionKey.ReductionTargetsSet,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNoMaybe>()
      .nullable()
      .oneOf([null, ...Object.values(YesNoMaybe)]),
  },
  [QuestionKey.ReductionTargets]: {
    key: QuestionKey.ReductionTargets,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.array(ReductionTargets).nullable(),
  },
  [QuestionKey.ModifiedProposeTarget]: {
    key: QuestionKey.ModifiedProposeTarget,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: ReductionTargets.nullable(),
  },
  [QuestionKey.AcceptedProposeTarget]: {
    key: QuestionKey.AcceptedProposeTarget,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: ReductionTargets.nullable(),
  },
  [QuestionKey.ReductionInitiativesSet]: {
    key: QuestionKey.ReductionInitiativesSet,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNoMaybe>()
      .nullable()
      .oneOf([null, ...Object.values(YesNoMaybe)]),
  },
  [QuestionKey.ReductionInitiatives]: {
    key: QuestionKey.ReductionInitiatives,
    defaultValue: [],
    isPrivateToSupplier: false,
    schema: Yup.array(ReductionInitiatives).nullable(),
  },
  [QuestionKey.RenewableEnergyTargets]: {
    key: QuestionKey.RenewableEnergyTargets,
    defaultValue: [],
    isPrivateToSupplier: false,
    schema: Yup.lazy(() => Yup.mixed()),
  },
  [QuestionKey.OtherTargets]: {
    key: QuestionKey.OtherTargets,
    defaultValue: [],
    isPrivateToSupplier: false,
    schema: Yup.lazy(() => Yup.mixed()),
  },
  [QuestionKey.EvaluateProposedTarget]: {
    key: QuestionKey.EvaluateProposedTarget,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<AcceptDeclineModify>()
      .nullable()
      .oneOf(Object.values(AcceptDeclineModify)),
  },
  [QuestionKey.TopMaterialIssues]: {
    key: QuestionKey.TopMaterialIssues,
    defaultValue: [],
    isPrivateToSupplier: false,
    schema: Yup.array(MaterialIssue).nullable(),
  },
  [QuestionKey.Comment]: {
    key: QuestionKey.Comment,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().nullable(),
  },
  [QuestionKey.ExpensesNaics]: {
    key: QuestionKey.ExpensesNaics,
    defaultValue: [],
    isPrivateToSupplier: true,
    schema: Yup.array(ExpenseNaics).nullable(),
  },
  [QuestionKey.CustomQuestions]: {
    key: QuestionKey.CustomQuestions,
    defaultValue: [],
    isPrivateToSupplier: false,
    schema: Yup.lazy(() => Yup.mixed()),
  },
  [QuestionKey.CeeAggregatedBuildingSize]: {
    key: QuestionKey.CeeAggregatedBuildingSize,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.CeeBuildings]: {
    key: QuestionKey.CeeBuildings,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.array(CeeBuildingItem).nullable(),
  },
  [QuestionKey.CeeBusinessAirMiles]: {
    key: QuestionKey.CeeBusinessAirMiles,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.CeeBusinessAirSpend]: {
    key: QuestionKey.CeeBusinessAirSpend,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.CeeBusinessAirSpendCurrency]: {
    key: QuestionKey.CeeBusinessAirSpendCurrency,
    defaultValue: '',
    isPrivateToSupplier: true,
    schema: Yup.string().nullable(),
  },
  [QuestionKey.CeeBusinessGroundMiles]: {
    key: QuestionKey.CeeBusinessGroundMiles,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.CeeCloud]: {
    key: QuestionKey.CeeCloud,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.boolean().nullable(),
  },
  [QuestionKey.CeeCloudProviders]: {
    key: QuestionKey.CeeCloudProviders,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.array(CeeCloudItem).nullable(),
  },
  [QuestionKey.CeeCloudTotalInstanceHours]: {
    key: QuestionKey.CeeCloudTotalInstanceHours,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.CeeComputationNonCloud]: {
    key: QuestionKey.CeeComputationNonCloud,
    defaultValue: false,
    isPrivateToSupplier: true,
    schema: Yup.boolean().nullable(),
  },
  [QuestionKey.CeeComputationNonCloudCleanPower]: {
    key: QuestionKey.CeeComputationNonCloudCleanPower,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.CeeConsulting]: {
    key: QuestionKey.CeeConsulting,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.CeeRemotePercent]: {
    key: QuestionKey.CeeRemotePercent,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.CeeOfficeDaysPerWeek]: {
    key: QuestionKey.CeeOfficeDaysPerWeek,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.CeePaperless]: {
    key: QuestionKey.CeePaperless,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.mixed<YesNoMaybe>()
      .nullable()
      .oneOf([null, ...Object.values(YesNoMaybe)]),
  },
  [QuestionKey.CeeRevenueIntensityByScope]: {
    key: QuestionKey.CeeRevenueIntensityByScope,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.lazy(() => Yup.mixed()),
  },
  [QuestionKey.CeeAbsoluteEmissionsByScope]: {
    key: QuestionKey.CeeAbsoluteEmissionsByScope,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.lazy(() => Yup.mixed()),
  },
  [QuestionKey.CeeTmsFileUpload]: {
    key: QuestionKey.CeeTmsFileUpload,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.array(AttachmentSchema).nullable(),
  },
  [QuestionKey.CeeTmsManualInput]: {
    key: QuestionKey.CeeTmsManualInput,
    defaultValue: [],
    isPrivateToSupplier: false,
    schema: Yup.array(TmsManualInput).nullable(),
  },
  [QuestionKey.CeeLogisticsAirKmTonnes]: {
    key: QuestionKey.CeeLogisticsAirKmTonnes,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.CeeLogisticsAirLoading]: {
    key: QuestionKey.CeeLogisticsAirLoading,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.CeeLogisticsFreezerSeaKmTonnes]: {
    key: QuestionKey.CeeLogisticsFreezerSeaKmTonnes,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.CeeLogisticsFreezerTruckKmTonnes]: {
    key: QuestionKey.CeeLogisticsFreezerTruckKmTonnes,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.CeeLogisticsRefrigeratedSeaKmTonnes]: {
    key: QuestionKey.CeeLogisticsRefrigeratedSeaKmTonnes,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.CeeLogisticsRefrigeratedTruckKmTonnes]: {
    key: QuestionKey.CeeLogisticsRefrigeratedTruckKmTonnes,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.CeeLogisticsSeaKmTonnes]: {
    key: QuestionKey.CeeLogisticsSeaKmTonnes,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.CeeLogisticsSeaLoading]: {
    key: QuestionKey.CeeLogisticsSeaLoading,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.CeeLogisticsTrainKmTonnes]: {
    key: QuestionKey.CeeLogisticsTrainKmTonnes,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.CeeLogisticsTrainLoading]: {
    key: QuestionKey.CeeLogisticsTrainLoading,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.CeeLogisticsTruckKmTonnes]: {
    key: QuestionKey.CeeLogisticsTruckKmTonnes,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.CeeLogisticsTruckLoading]: {
    key: QuestionKey.CeeLogisticsTruckLoading,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.CeeDairyNumberOfCows]: {
    key: QuestionKey.CeeDairyNumberOfCows,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.CeeDairyAvgMilkGallonsPerYear]: {
    key: QuestionKey.CeeDairyAvgMilkGallonsPerYear,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.CeeDairyProductionSystem]: {
    key: QuestionKey.CeeDairyProductionSystem,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.string().nullable(),
  },
  [QuestionKey.CeeDairyProductionSystemGrasslandSupplementaryDigestibilityFeed]:
    {
      key: QuestionKey.CeeDairyProductionSystemGrasslandSupplementaryDigestibilityFeed,
      defaultValue: null,
      isPrivateToSupplier: true,
      schema: Yup.mixed<YesNo>()
        .nullable()
        .oneOf([null, ...Object.values(YesNo)]),
    },
  [QuestionKey.CeeDairyProductionSystemGrasslandSupplementaryDigestibilityFeedKgPerCow]:
    {
      key: QuestionKey.CeeDairyProductionSystemGrasslandSupplementaryDigestibilityFeedKgPerCow,
      defaultValue: null,
      isPrivateToSupplier: true,
      schema: Yup.number().nullable(),
    },
  [QuestionKey.CeeDairyManureManagement]: {
    key: QuestionKey.CeeDairyManureManagement,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.string().nullable(),
  },
  [QuestionKey.CeeDairyManureManagementLiquidCap]: {
    key: QuestionKey.CeeDairyManureManagementLiquidCap,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.mixed<YesNo>()
      .nullable()
      .oneOf([null, ...Object.values(YesNo)]),
  },
  [QuestionKey.CeeWoodByCountry]: {
    key: QuestionKey.CeeWoodByCountry,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.array(CeeWoodItem).nullable(),
  },
  [QuestionKey.CeeBiomassPercentage]: {
    key: QuestionKey.CeeBiomassPercentage,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.CeePaperCleanPowerPercentage]: {
    key: QuestionKey.CeePaperCleanPowerPercentage,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.SfdrFossilFuelSector]: {
    key: QuestionKey.SfdrFossilFuelSector,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNoMaybe>()
      .nullable()
      .oneOf([null, ...Object.values(YesNoMaybe)]),
  },
  [QuestionKey.SfdrEnergyConsumptionHighImpact]: {
    key: QuestionKey.SfdrEnergyConsumptionHighImpact,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNoMaybe>()
      .nullable()
      .oneOf([null, ...Object.values(YesNoMaybe)]),
  },
  [QuestionKey.SfdrBiodiversitySensitiveAreas]: {
    key: QuestionKey.SfdrBiodiversitySensitiveAreas,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNoMaybe>()
      .nullable()
      .oneOf([null, ...Object.values(YesNoMaybe)]),
  },
  [QuestionKey.TotalEnergyConsumption]: {
    key: QuestionKey.TotalEnergyConsumption,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: NUMBER_FIELD_OPTIONAL_SCHEMA,
  },
  [QuestionKey.SfdrEmissionsToWater]: {
    key: QuestionKey.SfdrEmissionsToWater,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: NUMBER_FIELD_OPTIONAL_SCHEMA,
  },
  [QuestionKey.SfdrHazardousWaste]: {
    key: QuestionKey.SfdrHazardousWaste,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: NUMBER_FIELD_OPTIONAL_SCHEMA,
  },
  [QuestionKey.SfdrUnGlobalCompactViolations]: {
    key: QuestionKey.SfdrUnGlobalCompactViolations,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNoMaybe>()
      .nullable()
      .oneOf([null, ...Object.values(YesNoMaybe)]),
  },
  [QuestionKey.SfdrUnGlobalCompactCompliance]: {
    key: QuestionKey.SfdrUnGlobalCompactCompliance,
    defaultValue: [],
    isPrivateToSupplier: false,
    schema: Yup.array(
      Yup.object({
        key: Yup.string().required(),
        label: Yup.string().required(),
        checked: Yup.boolean().required(),
      })
    ).nullable(),
  },
  [QuestionKey.SfdrGenderPayGap]: {
    key: QuestionKey.SfdrGenderPayGap,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: NUMBER_FIELD_OPTIONAL_SCHEMA,
  },
  [QuestionKey.SfdrBoardGenderDiversity]: {
    key: QuestionKey.SfdrBoardGenderDiversity,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.SfdrExposureToControversialWeapons]: {
    key: QuestionKey.SfdrExposureToControversialWeapons,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNoMaybe>()
      .nullable()
      .oneOf([null, ...Object.values(YesNoMaybe)]),
  },
  [QuestionKey.EmployeeDiversity]: {
    key: QuestionKey.EmployeeDiversity,
    defaultValue: [],
    isPrivateToSupplier: false,
    schema: Yup.array(
      Yup.object({
        key: Yup.string().required(),
        value: Yup.number().nullable(),
        unknown: Yup.boolean().nullable(),
      })
    ).nullable(),
  },
  [QuestionKey.BoardDiversity]: {
    key: QuestionKey.BoardDiversity,
    defaultValue: [],
    isPrivateToSupplier: false,
    schema: Yup.array(
      Yup.object({
        key: Yup.string().required(),
        value: Yup.number().nullable(),
        unknown: Yup.boolean().nullable(),
      })
    ).nullable(),
  },
  [QuestionKey.CSuiteDiversity]: {
    key: QuestionKey.CSuiteDiversity,
    defaultValue: [],
    isPrivateToSupplier: false,
    schema: Yup.array(
      Yup.object({
        key: Yup.string().required(),
        value: Yup.number().nullable(),
        unknown: Yup.boolean().nullable(),
      })
    ).nullable(),
  },
  [QuestionKey.WorkplaceInjury]: {
    key: QuestionKey.WorkplaceInjury,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: NUMBER_FIELD_OPTIONAL_SCHEMA,
  },
  [QuestionKey.WorkplaceFatality]: {
    key: QuestionKey.WorkplaceFatality,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: NUMBER_FIELD_OPTIONAL_SCHEMA,
  },
  [QuestionKey.DaysLostDueToInjury]: {
    key: QuestionKey.DaysLostDueToInjury,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: NUMBER_FIELD_OPTIONAL_SCHEMA,
  },
  [QuestionKey.TotalEmployeesYearEnd]: {
    key: QuestionKey.TotalEmployeesYearEnd,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: NUMBER_FIELD_OPTIONAL_SCHEMA,
  },
  [QuestionKey.TotalEmployeesPriorYearEnd]: {
    key: QuestionKey.TotalEmployeesPriorYearEnd,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: NUMBER_FIELD_OPTIONAL_SCHEMA,
  },
  [QuestionKey.AnnualPercentTurnover]: {
    key: QuestionKey.AnnualPercentTurnover,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: NUMBER_FIELD_OPTIONAL_SCHEMA,
  },
  [QuestionKey.NetChangeEmployeesNonOrganic]: {
    key: QuestionKey.NetChangeEmployeesNonOrganic,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: NUMBER_FIELD_OPTIONAL_SCHEMA,
  },
  [QuestionKey.EmployeeSurvey]: {
    key: QuestionKey.EmployeeSurvey,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNo>()
      .nullable()
      .oneOf([null, ...Object.values(YesNo)]),
  },
  [QuestionKey.EmployeeSurveyKinds]: {
    key: QuestionKey.EmployeeSurveyKinds,
    defaultValue: [],
    isPrivateToSupplier: false,
    schema: Yup.array(
      Yup.object({
        key: Yup.string().required(),
        checked: Yup.boolean().nullable(),
      })
    ).nullable(),
  },
  [QuestionKey.EmployeeSurveyResponseRate]: {
    key: QuestionKey.EmployeeSurveyResponseRate,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: NUMBER_FIELD_OPTIONAL_SCHEMA,
  },
  // Clean power initiative questions.
  [QuestionKey.CleanPowerInitiativeRenewableEnergyUsage]: {
    key: QuestionKey.CleanPowerInitiativeRenewableEnergyUsage,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<RenewableEnergyUsage>()
      .nullable()
      .oneOf([null, ...Object.values(RenewableEnergyUsage)]),
  },
  [QuestionKey.CleanPowerInitiativeRenewableEnergyUsageImport]: {
    key: QuestionKey.CleanPowerInitiativeRenewableEnergyUsageImport,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.array(AttachmentSchema).nullable(),
  },
  [QuestionKey.CarbonRemoval]: {
    key: QuestionKey.CarbonRemoval,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNoMaybe>()
      .nullable()
      .oneOf([null, ...Object.values(YesNoMaybe)]),
  },
  [QuestionKey.CarbonRemovalAmount]: {
    key: QuestionKey.CarbonRemovalAmount,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().nullable().min(0, 'Please enter a positive number.'),
  },
  [QuestionKey.WasteManagement]: {
    key: QuestionKey.WasteManagement,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNoMaybe>()
      .nullable()
      .oneOf([null, ...Object.values(YesNoMaybe)]),
  },
  [QuestionKey.EnvironmentalRiskAssessment]: {
    key: QuestionKey.EnvironmentalRiskAssessment,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNoMaybe>()
      .nullable()
      .oneOf([null, ...Object.values(YesNoMaybe)]),
  },
  [QuestionKey.LivingWage]: {
    key: QuestionKey.LivingWage,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNoMaybe>()
      .nullable()
      .oneOf([null, ...Object.values(YesNoMaybe)]),
  },
  [QuestionKey.HealthBenefits]: {
    key: QuestionKey.HealthBenefits,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNoMaybe>()
      .nullable()
      .oneOf([null, ...Object.values(YesNoMaybe)]),
  },
  [QuestionKey.ParentalLeave]: {
    key: QuestionKey.ParentalLeave,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.object({
      primary: Yup.number().nullable(),
      secondary: Yup.number().nullable(),
    }).nullable(),
  },
  [QuestionKey.EsgRiskAssessment]: {
    key: QuestionKey.EsgRiskAssessment,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNoMaybe>()
      .nullable()
      .oneOf([null, ...Object.values(YesNoMaybe)]),
  },
  [QuestionKey.EsgBoardMember]: {
    key: QuestionKey.EsgBoardMember,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNoMaybe>()
      .nullable()
      .oneOf([null, ...Object.values(YesNoMaybe)]),
  },
  [QuestionKey.EsgBoardDiscussion]: {
    key: QuestionKey.EsgBoardDiscussion,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNoMaybe>()
      .nullable()
      .oneOf([null, ...Object.values(YesNoMaybe)]),
  },
  [QuestionKey.SbtEngagementStatus]: {
    key: QuestionKey.SbtEngagementStatus,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<GQCompanySbtCommitmentStage>()
      .nullable()
      .oneOf([null, ...Object.values(GQCompanySbtCommitmentStage)]),
  },
  [QuestionKey.SmeHeadcount]: {
    key: QuestionKey.SmeHeadcount,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNo>()
      .nullable()
      .oneOf([null, ...Object.values(YesNo)]),
  },
  [QuestionKey.HasSubmittedSbtiTargets]: {
    key: QuestionKey.HasSubmittedSbtiTargets,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNo>()
      .nullable()
      .oneOf([null, ...Object.values(YesNo)]),
  },
  [QuestionKey.HaveCommittedSbtiTargets]: {
    key: QuestionKey.HaveCommittedSbtiTargets,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNo>()
      .nullable()
      .oneOf([null, ...Object.values(YesNo)]),
  },
  [QuestionKey.SubmittedSbtiCommitment]: {
    key: QuestionKey.SubmittedSbtiCommitment,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNo>()
      .nullable()
      .oneOf([null, ...Object.values(YesNo)]),
  },
  [QuestionKey.SbtiCommitmentLetterImport]: {
    key: QuestionKey.SbtiCommitmentLetterImport,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.array(AttachmentSchema).required().min(1),
  },
  [QuestionKey.SbtGhgMeasurementHasCompletedMeasurement]: {
    key: QuestionKey.SbtGhgMeasurementHasCompletedMeasurement,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNo>()
      .nullable()
      .oneOf([null, ...Object.values(YesNo)]),
  },
  [QuestionKey.SbtDevelopTargetHasDevelopedTarget]: {
    key: QuestionKey.SbtDevelopTargetHasDevelopedTarget,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNo>()
      .nullable()
      .oneOf([null, ...Object.values(YesNo)]),
  },
  [QuestionKey.SbtSubmitTargetHasSubmittedTarget]: {
    key: QuestionKey.SbtSubmitTargetHasSubmittedTarget,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNo>()
      .nullable()
      .oneOf([null, ...Object.values(YesNo)]),
  },
  [QuestionKey.SbtSubmitTargetSubmissionLetterImport]: {
    key: QuestionKey.SbtSubmitTargetSubmissionLetterImport,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.array(AttachmentSchema).required().min(1),
  },

  [QuestionKey.SustainabilityProgram]: {
    key: QuestionKey.SustainabilityProgram,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNo>().oneOf(Object.values(YesNo)),
  },
  [QuestionKey.HelpNeeded]: {
    key: QuestionKey.HelpNeeded,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().required().typeError('Please enter an answer.'),
  },
  [QuestionKey.NoSustainabilityProgramHelpNeeded]: {
    key: QuestionKey.NoSustainabilityProgramHelpNeeded,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().nullable(),
  },
  [QuestionKey.NoGhgInventoryHelpNeeded]: {
    key: QuestionKey.NoGhgInventoryHelpNeeded,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().nullable(),
  },
  [QuestionKey.HasTargets]: {
    key: QuestionKey.HasTargets,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNo>().oneOf(Object.values(YesNo)),
  },
  [QuestionKey.HasSbtTargets]: {
    key: QuestionKey.HasSbtTargets,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNo>().oneOf(Object.values(YesNo)),
  },
  [QuestionKey.HelpNeededToSetSbtTargets]: {
    key: QuestionKey.HelpNeededToSetSbtTargets,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().nullable(),
  },
  [QuestionKey.HasEnvironmentalTeam]: {
    key: QuestionKey.HasEnvironmentalTeam,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<SustainabilityTeamSize>().oneOf(
      Object.values(SustainabilityTeamSize)
    ),
  },
  [QuestionKey.SustainabilityInitiatives]: {
    key: QuestionKey.SustainabilityInitiatives,
    defaultValue: [],
    isPrivateToSupplier: false,
    schema: Yup.array(
      Yup.mixed<GQDisclosureInitiativeType>().oneOf([
        null,
        ...Object.values(GQDisclosureInitiativeType),
      ])
    ),
  },
  [QuestionKey.SustainabilityInitiativesWithDetails]: {
    key: QuestionKey.SustainabilityInitiativesWithDetails,
    defaultValue: [],
    isPrivateToSupplier: false,
    schema: Yup.array(SustainabilityInitiatives).nullable(),
  },
  [QuestionKey.ExternalReporting]: {
    key: QuestionKey.ExternalReporting,
    defaultValue: [],
    isPrivateToSupplier: false,
    schema: Yup.array(
      Yup.mixed<ExternalReportingType>().oneOf([
        null,
        ...Object.values(ExternalReportingType),
      ])
    ),
  },
  [QuestionKey.GhgInventoryInLastYear]: {
    key: QuestionKey.GhgInventoryInLastYear,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNo>().oneOf(Object.values(YesNo)),
  },
  [QuestionKey.GhgInventoryInLastYearMethodology]: {
    key: QuestionKey.GhgInventoryInLastYearMethodology,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().nullable(),
  },
  [QuestionKey.SustainabilityCommitments]: {
    key: QuestionKey.SustainabilityCommitments,
    defaultValue: [],
    isPrivateToSupplier: false,
    schema: Yup.array(Yup.lazy(() => Yup.mixed())),
  },
  [QuestionKey.ReductionTargetsPlanOfAction]: {
    key: QuestionKey.ReductionTargetsPlanOfAction,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNo>().oneOf(Object.values(YesNo)),
  },
  [QuestionKey.HelpNeededToSetTargets]: {
    key: QuestionKey.HelpNeededToSetTargets,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().nullable(),
  },
  [QuestionKey.FacilityEnergySources]: {
    key: QuestionKey.FacilityEnergySources,
    defaultValue: [],
    isPrivateToSupplier: false,
    schema: Yup.array(
      Yup.mixed<FacilityEnergySources>()
        .defined()
        .oneOf<FacilityEnergySources>(Object.values(FacilityEnergySources))
    ).required(),
  },
  [QuestionKey.FacilityBiomassNonWoodUsageAmount]: {
    key: QuestionKey.FacilityBiomassNonWoodUsageAmount,
    defaultValue: 0,
    isPrivateToSupplier: false,
    schema: Yup.number().required().typeError(VALID_NUMBER_MESSAGE),
  },
  [QuestionKey.FacilityBiomassNonWoodUsageUnit]: {
    key: QuestionKey.FacilityBiomassNonWoodUsageUnit,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().typeError('Please enter a unit').required(),
  },
  [QuestionKey.FacilityBiomassNonWoodUsageFileUpload]: {
    key: QuestionKey.FacilityBiomassNonWoodUsageFileUpload,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.array(AttachmentSchema)
      .typeError('Please upload a document')
      .required(),
  },
  [QuestionKey.FacilityCoalUsageAmount]: {
    key: QuestionKey.FacilityCoalUsageAmount,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().required().typeError(VALID_NUMBER_MESSAGE),
  },
  [QuestionKey.FacilityCoalUsageUnit]: {
    key: QuestionKey.FacilityCoalUsageUnit,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().typeError('Please enter a unit').required(),
  },
  [QuestionKey.FacilityCoalUsageFileUpload]: {
    key: QuestionKey.FacilityCoalUsageFileUpload,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.array(AttachmentSchema)
      .typeError('Please upload a document')
      .required(),
  },
  [QuestionKey.FacilityElectrictyFromGridUsageAmount]: {
    key: QuestionKey.FacilityElectrictyFromGridUsageAmount,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().required().typeError(VALID_NUMBER_MESSAGE),
  },
  [QuestionKey.FacilityElectrictyFromGridUsageUnit]: {
    key: QuestionKey.FacilityElectrictyFromGridUsageUnit,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().typeError('Please enter a unit').required(),
  },
  [QuestionKey.FacilityElectrictyFromGridUsageFileUpload]: {
    key: QuestionKey.FacilityElectrictyFromGridUsageFileUpload,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.array(AttachmentSchema)
      .typeError('Please upload a document')
      .required(),
  },
  [QuestionKey.FacilityNaturalGasUsageAmount]: {
    key: QuestionKey.FacilityNaturalGasUsageAmount,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().required().typeError(VALID_NUMBER_MESSAGE),
  },
  [QuestionKey.FacilityNaturalGasUsageUnit]: {
    key: QuestionKey.FacilityNaturalGasUsageUnit,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().typeError('Please enter a unit').required(),
  },
  [QuestionKey.FacilityNaturalGasUsageFileUpload]: {
    key: QuestionKey.FacilityNaturalGasUsageFileUpload,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.array(AttachmentSchema)
      .typeError('Please upload a document')
      .required(),
  },
  [QuestionKey.FacilityPetrolUsageAmount]: {
    key: QuestionKey.FacilityPetrolUsageAmount,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().required().typeError(VALID_NUMBER_MESSAGE),
  },
  [QuestionKey.FacilityPetrolUsageUnit]: {
    key: QuestionKey.FacilityPetrolUsageUnit,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().typeError('Please enter a unit').required(),
  },
  [QuestionKey.FacilityPetrolUsageFileUpload]: {
    key: QuestionKey.FacilityPetrolUsageFileUpload,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.array(AttachmentSchema)
      .typeError('Please upload a document')
      .required(),
  },
  [QuestionKey.FacilityPropaneUsageAmount]: {
    key: QuestionKey.FacilityPropaneUsageAmount,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().required().typeError(VALID_NUMBER_MESSAGE),
  },
  [QuestionKey.FacilityPropaneUsageUnit]: {
    key: QuestionKey.FacilityPropaneUsageUnit,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().typeError('Please enter a unit').required(),
  },
  [QuestionKey.FacilityPropaneUsageFileUpload]: {
    key: QuestionKey.FacilityPropaneUsageFileUpload,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.array(AttachmentSchema)
      .typeError('Please upload a document')
      .required(),
  },
  [QuestionKey.FacilitySolarUsageAmount]: {
    key: QuestionKey.FacilitySolarUsageAmount,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().required().typeError(VALID_NUMBER_MESSAGE),
  },
  [QuestionKey.FacilitySolarUsageUnit]: {
    key: QuestionKey.FacilitySolarUsageUnit,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().typeError('Please enter a unit').required(),
  },
  [QuestionKey.FacilitySolarUsageFileUpload]: {
    key: QuestionKey.FacilitySolarUsageFileUpload,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.array(AttachmentSchema)
      .typeError('Please upload a document')
      .required(),
  },
  [QuestionKey.FacilityWoodUsageAmount]: {
    key: QuestionKey.FacilityWoodUsageAmount,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().required().typeError(VALID_NUMBER_MESSAGE),
  },
  [QuestionKey.FacilityWoodUsageUnit]: {
    key: QuestionKey.FacilityWoodUsageUnit,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().typeError('Please enter a unit').required(),
  },
  [QuestionKey.FacilityWoodUsageFileUpload]: {
    key: QuestionKey.FacilityWoodUsageFileUpload,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.array(AttachmentSchema)
      .typeError('Please upload a document')
      .required(),
  },

  // Water Usage Questions
  [QuestionKey.FacilityWaterSources]: {
    key: QuestionKey.FacilityWaterSources,
    defaultValue: [],
    isPrivateToSupplier: false,
    schema: Yup.array(
      Yup.mixed<FacilityWaterSources>()
        .defined()
        .oneOf<FacilityWaterSources>(Object.values(FacilityWaterSources))
    ).required(),
  },
  [QuestionKey.FacilityMunicipalWaterAmount]: {
    key: QuestionKey.FacilityMunicipalWaterAmount,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().required().typeError(VALID_NUMBER_MESSAGE),
  },
  [QuestionKey.FacilityMunicipalWaterUnit]: {
    key: QuestionKey.FacilityMunicipalWaterUnit,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().typeError('Please enter a unit').required(),
  },
  [QuestionKey.FacilityMunicipalWaterFileUpload]: {
    key: QuestionKey.FacilityMunicipalWaterFileUpload,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.array(AttachmentSchema)
      .typeError('Please upload a document')
      .required(),
  },
  [QuestionKey.FacilityRainwaterAmount]: {
    key: QuestionKey.FacilityRainwaterAmount,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().required().typeError(VALID_NUMBER_MESSAGE),
  },
  [QuestionKey.FacilityRainwaterUnit]: {
    key: QuestionKey.FacilityRainwaterUnit,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().typeError('Please enter a unit').required(),
  },
  [QuestionKey.FacilityRainwaterFileUpload]: {
    key: QuestionKey.FacilityRainwaterFileUpload,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.array(AttachmentSchema)
      .typeError('Please upload a document')
      .required(),
  },
  [QuestionKey.FacilityGroundwaterAmount]: {
    key: QuestionKey.FacilityGroundwaterAmount,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().required().typeError(VALID_NUMBER_MESSAGE),
  },
  [QuestionKey.FacilityGroundwaterUnit]: {
    key: QuestionKey.FacilityGroundwaterUnit,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().typeError('Please enter a unit').required(),
  },
  [QuestionKey.FacilityGroundwaterFileUpload]: {
    key: QuestionKey.FacilityGroundwaterFileUpload,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.array(AttachmentSchema)
      .typeError('Please upload a document')
      .required(),
  },
  [QuestionKey.FacilityWastewaterAmount]: {
    key: QuestionKey.FacilityWastewaterAmount,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().required().typeError(VALID_NUMBER_MESSAGE),
  },
  [QuestionKey.FacilityWastewaterUnit]: {
    key: QuestionKey.FacilityWastewaterUnit,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().typeError('Please enter a unit').required(),
  },
  [QuestionKey.FacilityWastewaterFileUpload]: {
    key: QuestionKey.FacilityWastewaterFileUpload,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.array(AttachmentSchema)
      .typeError('Please upload a document')
      .required(),
  },
  [QuestionKey.FacilityBrackishSurfaceWaterOrSeawaterAmount]: {
    key: QuestionKey.FacilityBrackishSurfaceWaterOrSeawaterAmount,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().required().typeError(VALID_NUMBER_MESSAGE),
  },
  [QuestionKey.FacilityBrackishSurfaceWaterOrSeawaterUnit]: {
    key: QuestionKey.FacilityBrackishSurfaceWaterOrSeawaterUnit,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().typeError('Please enter a unit').required(),
  },
  [QuestionKey.FacilityBrackishSurfaceWaterOrSeawaterFileUpload]: {
    key: QuestionKey.FacilityBrackishSurfaceWaterOrSeawaterFileUpload,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.array(AttachmentSchema)
      .typeError('Please upload a document')
      .required(),
  },
  [QuestionKey.FacilityOtherAmount]: {
    key: QuestionKey.FacilityOtherAmount,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().required().typeError(VALID_NUMBER_MESSAGE),
  },
  [QuestionKey.FacilityOtherUnit]: {
    key: QuestionKey.FacilityOtherUnit,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().typeError('Please enter a unit').required(),
  },
  [QuestionKey.FacilityOtherFileUpload]: {
    key: QuestionKey.FacilityOtherFileUpload,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.array(AttachmentSchema)
      .typeError('Please upload a document')
      .required(),
  },

  [QuestionKey.FacilityProductionListOfTypesOfProducts]: {
    key: QuestionKey.FacilityProductionListOfTypesOfProducts,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().typeError('Please list types of products').required(),
  },
  [QuestionKey.FacilityProductionTotalNumberOfUnits]: {
    key: QuestionKey.FacilityProductionTotalNumberOfUnits,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().required().typeError(VALID_NUMBER_MESSAGE),
  },
  [QuestionKey.FacilityDetailedAllocationDoesSubMeterOrTrackProperties]: {
    key: QuestionKey.FacilityDetailedAllocationDoesSubMeterOrTrackProperties,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNo>().oneOf(Object.values(YesNo)),
  },
  [QuestionKey.FacilityDetailedAllocationDoesTrackMetricForCompanyProducts]: {
    key: QuestionKey.FacilityDetailedAllocationDoesTrackMetricForCompanyProducts,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNo>().oneOf(Object.values(YesNo)),
  },
  [QuestionKey.FacilityDetailedAllocationTotalAmountOfMetricForCompany]: {
    key: QuestionKey.FacilityDetailedAllocationTotalAmountOfMetricForCompany,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().required().typeError(VALID_NUMBER_MESSAGE),
  },
  [QuestionKey.FacilityDetailedAllocationTotalAmountOfMetricForEntireFacility]:
    {
      key: QuestionKey.FacilityDetailedAllocationTotalAmountOfMetricForEntireFacility,
      defaultValue: null,
      isPrivateToSupplier: false,
      schema: Yup.number().required().typeError(VALID_NUMBER_MESSAGE),
    },
  [QuestionKey.FacilityDetailedAllocationMetricUnit]: {
    key: QuestionKey.FacilityDetailedAllocationMetricUnit,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().typeError('Please enter a unit'),
  },
  [QuestionKey.FacilityDetailedAllocationRelevantReport]: {
    key: QuestionKey.FacilityDetailedAllocationRelevantReport,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.array(AttachmentSchema).nullable(),
  },

  [QuestionKey.FacilityInfoName]: {
    key: QuestionKey.FacilityInfoName,
    defaultValue: '',
    isPrivateToSupplier: false,
    schema: Yup.string().required(`Please enter your facility's name`),
  },
  [QuestionKey.FacilityInfoAddress]: {
    key: QuestionKey.FacilityInfoAddress,
    defaultValue: '',
    isPrivateToSupplier: false,
    schema: Yup.string().required('Please enter an address'),
  },
  [QuestionKey.FacilityInfoIndustry]: {
    key: QuestionKey.FacilityInfoIndustry,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().typeError('Please enter an industry'),
  },
  [QuestionKey.FacilityInfoCountryOfOperation]: {
    key: QuestionKey.FacilityInfoCountryOfOperation,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().typeError('Please select a country of operation'),
  },
  [QuestionKey.FacilityInfoHeadcount]: {
    key: QuestionKey.FacilityInfoHeadcount,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().min(0).typeError('Please enter a valid number'),
  },
  [QuestionKey.FacilityInfoListAllProcesses]: {
    key: QuestionKey.FacilityInfoListAllProcesses,
    defaultValue: '',
    isPrivateToSupplier: false,
    schema: Yup.string().required('Please list all processes'),
  },

  [QuestionKey.FacilityCleanPowerDoesUseCleanPower]: {
    key: QuestionKey.FacilityCleanPowerDoesUseCleanPower,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNoMaybe>()
      .nullable()
      .oneOf([null, ...Object.values(YesNoMaybe)]),
  },
  [QuestionKey.FacilityCleanPowerTotalAmountInMWhCoveredByCleanPower]: {
    key: QuestionKey.FacilityCleanPowerTotalAmountInMWhCoveredByCleanPower,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().required('Please enter a valid number'),
  },
  [QuestionKey.FacilityCleanPowerDescribeCleanPowerSources]: {
    key: QuestionKey.FacilityCleanPowerDescribeCleanPowerSources,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().required('Please describe your clean energy sources'),
  },
  [QuestionKey.FacilityCleanPowerRelevantReports]: {
    key: QuestionKey.FacilityCleanPowerRelevantReports,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.array(AttachmentSchema).nullable(),
  },

  [QuestionKey.LearningVideosFinished]: {
    key: QuestionKey.LearningVideosFinished,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.lazy(() => Yup.mixed()),
  },
  [QuestionKey.LearningBusinessCaseCorporateClimateActionBenefits]: {
    key: QuestionKey.LearningBusinessCaseCorporateClimateActionBenefits,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.string(),
  },
  [QuestionKey.LearningBusinessCaseRisk]: {
    key: QuestionKey.LearningBusinessCaseRisk,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.string(),
  },
  [QuestionKey.LearningBusinessCaseMarketTrends]: {
    key: QuestionKey.LearningBusinessCaseMarketTrends,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.LearningBusinessCaseOperationalCosts]: {
    key: QuestionKey.LearningBusinessCaseOperationalCosts,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.string(),
  },
  [QuestionKey.LearningBusinessCaseEmployees]: {
    key: QuestionKey.LearningBusinessCaseEmployees,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.string(),
  },
  [QuestionKey.LearningMeasuringEmissionsScopeOne]: {
    key: QuestionKey.LearningMeasuringEmissionsScopeOne,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.array(Yup.string()),
  },
  [QuestionKey.LearningMeasuringEmissionsScopeTwo]: {
    key: QuestionKey.LearningMeasuringEmissionsScopeTwo,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.array(Yup.string()),
  },
  [QuestionKey.LearningMeasuringEmissionsScopeThree]: {
    key: QuestionKey.LearningMeasuringEmissionsScopeThree,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.array(Yup.string()),
  },
  [QuestionKey.LearningMeasuringEmissionsCarbonAccounting]: {
    key: QuestionKey.LearningMeasuringEmissionsCarbonAccounting,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.string(),
  },
  [QuestionKey.LearningMeasuringEmissionsBusinessActivities]: {
    key: QuestionKey.LearningMeasuringEmissionsBusinessActivities,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.string(),
  },
  [QuestionKey.LearningMeasuringEmissionsEmissionsFactors]: {
    key: QuestionKey.LearningMeasuringEmissionsEmissionsFactors,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.string(),
  },
  [QuestionKey.LearningMeasuringEmissionsSpectrum]: {
    key: QuestionKey.LearningMeasuringEmissionsSpectrum,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.string(),
  },
  [QuestionKey.LearningEngageYourTeamEngagingLeadershipTactics]: {
    key: QuestionKey.LearningEngageYourTeamEngagingLeadershipTactics,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.string(),
  },
  [QuestionKey.LearningEngageYourTeamIdentifyingStakeholders]: {
    key: QuestionKey.LearningEngageYourTeamIdentifyingStakeholders,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.string(),
  },
  [QuestionKey.LearningEngageYourTeamEngagingEmployeesTactics]: {
    key: QuestionKey.LearningEngageYourTeamEngagingEmployeesTactics,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.string(),
  },
  [QuestionKey.LearningScienceBasedTargetsDefinition3]: {
    key: QuestionKey.LearningScienceBasedTargetsDefinition3,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.string(),
  },
  [QuestionKey.LearningScienceBasedTargetsClimateScienceBasics]: {
    key: QuestionKey.LearningScienceBasedTargetsClimateScienceBasics,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.string(),
  },
  [QuestionKey.LearningScienceBasedTargetsNumberOfCompaniesCommitted]: {
    key: QuestionKey.LearningScienceBasedTargetsNumberOfCompaniesCommitted,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.string(),
  },
  [QuestionKey.LearningScienceBasedTargetsDevelopTargetKeyQuestions]: {
    key: QuestionKey.LearningScienceBasedTargetsDevelopTargetKeyQuestions,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.string(),
  },
  [QuestionKey.LearningLaunchingAClimateProgramSupport]: {
    key: QuestionKey.LearningLaunchingAClimateProgramSupport,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.string(),
  },
  [QuestionKey.LearningLaunchingAClimateProgramVoluntaryReporting]: {
    key: QuestionKey.LearningLaunchingAClimateProgramVoluntaryReporting,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.string(),
  },
  [QuestionKey.LearningLaunchingAClimateProgramAmbitionLevelSpectrum]: {
    key: QuestionKey.LearningLaunchingAClimateProgramAmbitionLevelSpectrum,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number(),
  },
  [QuestionKey.LearningLaunchingAClimateProgramLongTerm]: {
    key: QuestionKey.LearningLaunchingAClimateProgramLongTerm,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.string(),
  },
  [QuestionKey.LearningCorporateClimateActionStep2]: {
    key: QuestionKey.LearningCorporateClimateActionStep2,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number(),
  },
  [QuestionKey.LearningCorporateClimateActionStep3]: {
    key: QuestionKey.LearningCorporateClimateActionStep3,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.string(),
  },
  [QuestionKey.LearningCorporateClimateActionReduceYourEmissions]: {
    key: QuestionKey.LearningCorporateClimateActionReduceYourEmissions,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.string(),
  },
  [QuestionKey.HasMeasuredScope3]: {
    key: QuestionKey.HasMeasuredScope3,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNo>().oneOf(Object.values(YesNo)),
  },
  [QuestionKey.HelpNeededSustainabilityProgram]: {
    key: QuestionKey.HelpNeededSustainabilityProgram,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().required().typeError('Please enter an answer.'),
  },
  [QuestionKey.HelpNeededMeasuringEmissions]: {
    key: QuestionKey.HelpNeededMeasuringEmissions,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().required().typeError('Please enter an answer.'),
  },
  [QuestionKey.HelpNeededSettingTargets]: {
    key: QuestionKey.HelpNeededSettingTargets,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().required().typeError('Please enter an answer.'),
  },
  [QuestionKey.HelpNeededReductionTargets]: {
    key: QuestionKey.HelpNeededReductionTargets,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().required().typeError('Please enter an answer.'),
  },
  [QuestionKey.HelpNeededSbtTargets]: {
    key: QuestionKey.HelpNeededSbtTargets,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().required().typeError('Please enter an answer.'),
  },
  [QuestionKey.PlanOfRecordCommitToWorkingTowardsSbt]: {
    key: QuestionKey.PlanOfRecordCommitToWorkingTowardsSbt,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNo>().oneOf(Object.values(YesNo)),
  },
  [QuestionKey.PlanOfRecordCommitToWorkingTowardsSbtNoExplain]: {
    key: QuestionKey.PlanOfRecordCommitToWorkingTowardsSbtNoExplain,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().required().typeError('Please enter an answer.'),
  },
  [QuestionKey.PlanOfRecordSubmittedSbtLetter]: {
    key: QuestionKey.PlanOfRecordSubmittedSbtLetter,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNo>().oneOf(Object.values(YesNo)),
  },
  [QuestionKey.PlanOfRecordSubmittedSbtLetterFileUpload]: {
    key: QuestionKey.PlanOfRecordSubmittedSbtLetterFileUpload,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.array(AttachmentSchema).optional(),
  },
  [QuestionKey.PlanOfRecordSubmittedSbtLetterNoExplain]: {
    key: QuestionKey.PlanOfRecordSubmittedSbtLetterNoExplain,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().required().typeError('Please enter an answer.'),
  },
  [QuestionKey.PlanOfRecordSubmittedSbtLetterExpectedDate]: {
    key: QuestionKey.PlanOfRecordSubmittedSbtLetterExpectedDate,
    defaultValue: YM.now(),
    isPrivateToSupplier: false,
    schema: Yup.number().required(),
  },
  [QuestionKey.PlanOfRecordCommitToMeasureGhg]: {
    key: QuestionKey.PlanOfRecordCommitToMeasureGhg,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNo>().oneOf(Object.values(YesNo)),
  },
  [QuestionKey.PlanOfRecordCommitToMeasureGhgNoExplain]: {
    key: QuestionKey.PlanOfRecordCommitToMeasureGhgNoExplain,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().required().typeError('Please enter an answer.'),
  },
  [QuestionKey.ExpenseCategories]: {
    key: QuestionKey.ExpenseCategories,
    defaultValue: null,
    isPrivateToSupplier: false,
    // array of GQDiscloseEmissionsExpenseCategories
    schema: Yup.array(
      Yup.object({
        beaCode: Yup.string().required(),
        inputUnit: Yup.string().required(),
        inputQuantity: Yup.number().required(),
      })
    ).nullable(),
  },

  [QuestionKey.LearningFinanceImpValue]: {
    key: QuestionKey.LearningFinanceImpValue,
    defaultValue: null,
    schema: Yup.string(),
  },
  [QuestionKey.LearningFinanceImpRecap]: {
    key: QuestionKey.LearningFinanceImpValue,
    defaultValue: null,
    schema: Yup.string(),
  },
  [QuestionKey.LearningFinanceImpEmissionsQuantificationVideoFinished]: {
    key: QuestionKey.LearningFinanceImpEmissionsQuantificationVideoFinished,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.lazy(() => Yup.mixed()),
  },
  [QuestionKey.LearningFinanceImpDataManagementVideoFinished]: {
    key: QuestionKey.LearningFinanceImpDataManagementVideoFinished,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.lazy(() => Yup.mixed()),
  },
  [QuestionKey.LearningFinanceImpManagementToolsVideoFinished]: {
    key: QuestionKey.LearningFinanceImpManagementToolsVideoFinished,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.lazy(() => Yup.mixed()),
  },
  [QuestionKey.LearningFinanceImpAuditingVideoFinished]: {
    key: QuestionKey.LearningFinanceImpAuditingVideoFinished,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.lazy(() => Yup.mixed()),
  },
  [QuestionKey.LearningFinanceImpDeepDiveVideoFinished]: {
    key: QuestionKey.LearningFinanceImpDeepDiveVideoFinished,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.lazy(() => Yup.mixed()),
  },
  [QuestionKey.LearningFinanceImpDataManagementSlider]: {
    key: QuestionKey.LearningFinanceImpDataManagementSlider,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.LearningFinanceImpAuditSlider]: {
    key: QuestionKey.LearningFinanceImpAuditSlider,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.MeasurementProjectsToShare]: {
    key: QuestionKey.MeasurementProjectsToShare,
    defaultValue: [],
    isPrivateToSupplier: false,
    schema: Yup.array(
      Yup.object({
        id: Yup.string().required(),
        name: Yup.string().required(),
        checked: Yup.boolean().required(),
        coverageStartDate: Yup.date().required(),
        coverageEndDate: Yup.date().required(),
        taskStatusDumps: Yup.array(
          Yup.object({
            taskName: Yup.string().required(),
            status: Yup.mixed<GQTaskWatershedProcessStateSimplified>().oneOf(
              Object.values(GQTaskWatershedProcessStateSimplified)
            ),
            datasetName: Yup.string().nullable(),
            assigneeNames: Yup.array(Yup.string().required()),
          })
        ),
        footprintReviewRequests: Yup.array(
          Yup.object({
            id: Yup.string().required(),
            footprintVersionId: Yup.string().required(),
            footprintSnapshotId: Yup.string().nullable(),
            footprintVersionKind: Yup.string().nullable(),
            status: Yup.mixed<GQWatershedFootprintReviewRequestStatus>().oneOf(
              Object.values(GQWatershedFootprintReviewRequestStatus)
            ),
          })
        ),
      })
    )
      .test(
        'at-least-one-checked',
        'At least one project must be checked',
        function (value) {
          if (!value) {
            return false; // Fail validation if the array is empty
          }
          // Check if at least one item has checked set to true
          return value.some((item) => item.checked);
        }
      )
      .nullable(),
  },

  [QuestionKey.ShareFootprint]: {
    key: QuestionKey.ShareFootprint,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.object({
      id: Yup.string().required(),
      name: Yup.string().required(),
    }).nullable(),
  },

  [QuestionKey.ShareFootprintDisclosureData]: {
    key: QuestionKey.ShareFootprintDisclosureData,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.object({
      footprintDisclosureId: Yup.string().required(),
      finalHeadcount: Yup.number().required(),
      totalRevenueUsdCents: Yup.number().required(),
      name: Yup.string().required(),
      size: Yup.number().required(),
      bucketName: Yup.string().required(),
      remotePath: Yup.string().required(),
    }).nullable(),
  },

  [QuestionKey.VisibilityOfCountryForAnimalFeed]: {
    key: QuestionKey.VisibilityOfCountryForAnimalFeed,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().nullable(),
  },

  // Start derived question keys
  [QuestionKey.DerivedSustainabilityInitiatives]: {
    key: QuestionKey.DerivedSustainabilityInitiatives,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.mixed<YesNo>().oneOf(Object.values(YesNo)).nullable(),
  },

  // End derived question keys

  // DEPRECATED
  [QuestionKey.Operations]: {
    key: QuestionKey.Operations,
    defaultValue: null,
    isPrivateToSupplier: true,
    schema: Yup.lazy(() => Yup.mixed()),
  },
  // DEPRECATED
  [QuestionKey.ActivitiesNaics]: {
    key: QuestionKey.ActivitiesNaics,
    defaultValue: [],
    isPrivateToSupplier: true,
    schema: Yup.lazy(() => Yup.mixed()),
  },
  // DEPRECATED
  [QuestionKey.ReducedTravelPercentage]: {
    key: QuestionKey.ReducedTravelPercentage,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().nullable(),
  },
  // DEPRECATED
  [QuestionKey.Paperless]: {
    key: QuestionKey.Paperless,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNoMaybe>()
      .nullable()
      .oneOf([null, ...Object.values(YesNoMaybe)]),
  },
  // DEPRECATED
  [QuestionKey.CarbonCommitments]: {
    key: QuestionKey.CarbonCommitments,
    defaultValue: '',
    isPrivateToSupplier: false,
    schema: Yup.string().nullable(),
  },
  // DEPRECATED
  [QuestionKey.ShareMethod]: {
    key: QuestionKey.ShareMethod,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().required(
      'Please enter how you will share your information.'
    ),
  },
  // DEPRECATED
  [QuestionKey.ContactEmail]: {
    key: QuestionKey.ContactEmail,
    defaultValue: '',
    isPrivateToSupplier: false,
    schema: Yup.string()
      .email('Please enter a valid email address.')
      .required('Please enter an email address.')
      .max(1000, 'Email address must be less than 1,000 characters.'),
  },
  // DEPRECATED
  [QuestionKey.ContactName]: {
    key: QuestionKey.ContactName,
    defaultValue: '',
    isPrivateToSupplier: false,
    schema: Yup.string()
      .required('Please enter your name.')
      .max(1000, 'Your name must be less than 1,000 characters.'),
  },
  // DEPRECATED
  [QuestionKey.ContactTitle]: {
    key: QuestionKey.ContactTitle,
    defaultValue: '',
    isPrivateToSupplier: false,
    schema: Yup.string().required('Please enter your job title.'),
  },

  // EDCI Questions

  [QuestionKey.EdciCountryOfDomicile]: {
    key: QuestionKey.EdciCountryOfDomicile,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string()
      .required()
      .typeError('Please enter the country of your headquarters.'),
  },
  [QuestionKey.EdciCountryOfOperations]: {
    key: QuestionKey.EdciCountryOfOperations,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string()
      .required()
      .typeError('Please enter your primary country of operations.'),
  },
  [QuestionKey.EdciCompanyStructure]: {
    key: QuestionKey.EdciCompanyStructure,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().nullable(),
  },
  [QuestionKey.EdciCompanyGrowthStage]: {
    key: QuestionKey.EdciCompanyGrowthStage,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string()
      .required()
      .typeError('Please enter your company growth stage.'),
  },
  [QuestionKey.EdciPrimarySectorOfOperations]: {
    key: QuestionKey.EdciPrimarySectorOfOperations,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<GQSasbSectorType>()
      .oneOf(Object.values(GQSasbSectorType))
      .required()
      .typeError('Please enter your primary sector of operations.'),
  },

  [QuestionKey.EdciPrimaryIndustryOfOperations]: {
    key: QuestionKey.EdciPrimaryIndustryOfOperations,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<GQSasbIndustryType>()
      .oneOf(Object.values(GQSasbIndustryType))
      .required()
      .typeError('Please enter your primary industry of operations.'),
  },
  [QuestionKey.EdciFinancialDataCurrency]: {
    key: QuestionKey.EdciFinancialDataCurrency,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().required().typeError('Please enter a currency.'),
  },
  [QuestionKey.EdciAnnualRevenueInMostRecentCalendarYear]: {
    key: QuestionKey.EdciAnnualRevenueInMostRecentCalendarYear,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number()
      .required()
      .typeError('Please enter your annual revenue.'),
  },
  [QuestionKey.EdciFteCountEndOfYear]: {
    key: QuestionKey.EdciFteCountEndOfYear,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().required().typeError('Please enter a number.'),
  },
  [QuestionKey.EdciFteCountEndOfPriorYear]: {
    key: QuestionKey.EdciFteCountEndOfPriorYear,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().required().typeError('Please enter a number.'),
  },
  [QuestionKey.EdciScope1Emissions]: {
    key: QuestionKey.EdciScope1Emissions,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.EdciScope1Methodology]: {
    key: QuestionKey.EdciScope1Methodology,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<GQEdciScope1MethodologyOption>().oneOf([
      null,
      ...Object.values(GQEdciScope1MethodologyOption),
    ]),
  },
  [QuestionKey.EdciScope2Emissions]: {
    key: QuestionKey.EdciScope2Emissions,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.EdciScope2Methodology]: {
    key: QuestionKey.EdciScope2Methodology,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<GQEdciScope2MethodologyOption>().oneOf([
      null,
      ...Object.values(GQEdciScope2MethodologyOption),
    ]),
  },
  [QuestionKey.EdciScope3Emissions]: {
    key: QuestionKey.EdciScope3Emissions,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.EdciScope3Methdology]: {
    key: QuestionKey.EdciScope3Methdology,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<GQEsgdcGhgMethodologyType>().oneOf([
      null,
      ...Object.values(GQEsgdcGhgMethodologyType),
    ]),
  },
  [QuestionKey.EdciAdditionalMethodologyDetails]: {
    key: QuestionKey.EdciAdditionalMethodologyDetails,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().nullable(),
  },
  [QuestionKey.EdciEmissionsDataReviewDetails]: {
    key: QuestionKey.EdciEmissionsDataReviewDetails,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().nullable(),
  },
  [QuestionKey.EdciDecarbonizationPlan]: {
    key: QuestionKey.EdciDecarbonizationPlan,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string(),
  },
  [QuestionKey.EdciShortTermReductionTarget]: {
    key: QuestionKey.EdciShortTermReductionTarget,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string(),
  },
  [QuestionKey.EdciLongTermNetZeroGoal]: {
    key: QuestionKey.EdciLongTermNetZeroGoal,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string(),
  },
  [QuestionKey.EdciAdditionalGhgTargetDetails]: {
    key: QuestionKey.EdciAdditionalGhgTargetDetails,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.string().nullable(),
  },
  [QuestionKey.EdciTotalEnergyConsumption]: {
    key: QuestionKey.EdciTotalEnergyConsumption,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().required().typeError('Please enter a number.'),
  },
  [QuestionKey.EdciTotalRenewableEnergyConsumption]: {
    key: QuestionKey.EdciTotalRenewableEnergyConsumption,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().required().typeError('Please enter a number.'),
  },
  [QuestionKey.EdciTotalBoardMembers]: {
    key: QuestionKey.EdciTotalBoardMembers,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().required().typeError('Please enter a number.'),
  },
  [QuestionKey.EdciWomenBoardMembers]: {
    key: QuestionKey.EdciWomenBoardMembers,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().required().typeError('Please enter a number.'),
  },
  [QuestionKey.EdciLgbtqBoardMembers]: {
    key: QuestionKey.EdciLgbtqBoardMembers,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.EdciUnderrepresentedGroupBoardMembers]: {
    key: QuestionKey.EdciUnderrepresentedGroupBoardMembers,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.EdciTotalCSuitePositions]: {
    key: QuestionKey.EdciTotalCSuitePositions,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.EdciTotalWomenCSuitePositions]: {
    key: QuestionKey.EdciTotalWomenCSuitePositions,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().nullable(),
  },
  [QuestionKey.EdciWorkRelatedInjuries]: {
    key: QuestionKey.EdciWorkRelatedInjuries,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().required().typeError('Please enter a number.'),
  },
  [QuestionKey.EdciWorkRelatedFatalities]: {
    key: QuestionKey.EdciWorkRelatedFatalities,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().required().typeError('Please enter a number.'),
  },
  [QuestionKey.EdciDaysLostToInjury]: {
    key: QuestionKey.EdciDaysLostToInjury,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().required().typeError('Please enter a number.'),
  },
  [QuestionKey.EdciNetChangeFteDueToMnA]: {
    key: QuestionKey.EdciNetChangeFteDueToMnA,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().required().typeError('Please enter a number.'),
  },
  [QuestionKey.EdciOrganicNetNewHires]: {
    key: QuestionKey.EdciOrganicNetNewHires,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().required().typeError('Please enter a number.'),
  },
  [QuestionKey.EdciTotalNetNewHires]: {
    key: QuestionKey.EdciTotalNetNewHires,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().required().typeError('Please enter a number.'),
  },
  [QuestionKey.EdciAnnualPercentTurnoverForFtes]: {
    key: QuestionKey.EdciAnnualPercentTurnoverForFtes,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().typeError('Please enter a number.'),
  },
  [QuestionKey.EdciEmployeeSurvey]: {
    key: QuestionKey.EdciEmployeeSurvey,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.mixed<YesNo>()
      .oneOf(Object.values(YesNo))
      .required()
      .typeError('Please select an answer.'),
  },
  [QuestionKey.EdciPercentResponseEmployeeSurvey]: {
    key: QuestionKey.EdciPercentResponseEmployeeSurvey,
    defaultValue: null,
    isPrivateToSupplier: false,
    schema: Yup.number().nullable(),
  },
};

export type SurveyFormValues = Partial<
  Omit<
    {
      [K in QuestionKey]: Yup.InferType<(typeof QUESTION_SCHEMAS)[K]['schema']>;
    },
    'ReductionInitiatives' | 'SustainabilityInitiativesWithDetails'
  > & {
    [QuestionKey.ReductionInitiatives]: Array<ReductionInitiativesType>;
    [QuestionKey.SustainabilityInitiativesWithDetails]: Array<SustainabilityInitiativesType>;
  }
> &
  Record<string, any>;

export type SurveyFormAttachments = {
  [questionKey: QuestionKeyExtendedType]: SurveyAttachmentAnswer;
};

export const DEFAULT_EXPLANATION = '';

export const ATTACHMENTS_SUFFIX = '_attachments';
export function getAttachmentsKey(
  questionKey: QuestionKeyExtendedType
): string {
  return `${questionKey}${ATTACHMENTS_SUFFIX}`;
}
export function isAttachmentsKey(
  questionKey: QuestionKeyExtendedType
): boolean {
  return questionKey.endsWith(ATTACHMENTS_SUFFIX);
}

export const EMPTY_SURVEY_FORM_VALUES: SurveyFormValues = Object.values(
  QuestionKey
).reduce((acc, questionKey) => {
  return {
    ...acc,
    [questionKey]: QUESTION_SCHEMAS[questionKey].defaultValue,
  };
}, {} as SurveyFormValues);

// Returns the top expense industry codes for a given industry code
export function getTopExpenseCodesForBeaCode(
  industryCode: string
): Array<string> {
  const topIndustries =
    surveyExpenseCodeMapping[industryCode as keyof SurveyExpenseCodeMapping];
  const topIndustryCodes = toPairs(topIndustries)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 5)
    .map(([key]) => key);
  return topIndustryCodes;
}

export const ABSOLUTE_EMISSIONS_TO_REVENUE_INTENSITY_QUESTION_KEY_MAP: Record<
  QuestionKeyExtendedType,
  string
> = {
  // Scope 1
  [QuestionKey.GhgScope1TonnesCo2e]: QuestionKey.GhgScope1RevenueIntensity,
  [QuestionKey.GhgScope1FugitiveEmissionsTonnesCo2e]:
    QuestionKey.GhgScope1FugitiveEmissionsRevenueIntensity,
  [QuestionKey.GhgScope1MobileCombustionTonnesCo2e]:
    QuestionKey.GhgScope1MobileCombustionRevenueIntensity,
  [QuestionKey.GhgScope1StationaryCombustionTonnesCo2e]:
    QuestionKey.GhgScope1StationaryCombustionRevenueIntensity,
  // Scope 2
  [QuestionKey.GhgScope2MarketBasedTonnesCo2e]:
    QuestionKey.GhgScope2MarketBasedRevenueIntensity,
  [QuestionKey.GhgScope2LocationBasedTonnesCo2e]:
    QuestionKey.GhgScope2LocationBasedRevenueIntensity,
  // Scope 3
  [QuestionKey.GhgScope3TotalTonnesCo2e]:
    QuestionKey.GhgScope3TotalRevenueIntensity,
  [QuestionKey.GhgScope3ByCategoryTonnesCo2e]:
    QuestionKey.GhgScope3ByCategoryRevenueIntensity,
};

export const CEE_ACTIVITY_QUESTION_KEYS: Array<QuestionKeyExtendedType> = [
  QuestionKey.CeeBuildings,
  QuestionKey.CeeBusinessAirMiles,
  QuestionKey.CeeBusinessAirSpend,
  QuestionKey.CeeBusinessAirSpendCurrency,
  QuestionKey.CeeBusinessGroundMiles,
  QuestionKey.CeeCloud,
  QuestionKey.CeeCloudProviders,
  QuestionKey.CeeCloudTotalInstanceHours,
  QuestionKey.CeeComputationNonCloud,
  QuestionKey.CeeComputationNonCloudCleanPower,
  QuestionKey.CeeConsulting,
  QuestionKey.CeeRemotePercent,
  QuestionKey.CeeOfficeDaysPerWeek,
  QuestionKey.CeePaperless,
];

export const REVENUE_INTENSITY_EMISSION_QUESTION_KEYS: Array<QuestionKeyExtendedType> =
  [
    QuestionKey.GhgScope1RevenueIntensity,
    QuestionKey.GhgScope1FugitiveEmissionsRevenueIntensity,
    QuestionKey.GhgScope1MobileCombustionRevenueIntensity,
    QuestionKey.GhgScope1StationaryCombustionRevenueIntensity,
    QuestionKey.GhgScope2MarketBasedRevenueIntensity,
    QuestionKey.GhgScope2LocationBasedRevenueIntensity,
    QuestionKey.GhgScope3TotalRevenueIntensity,
    QuestionKey.GhgScope3ByCategoryRevenueIntensity,
  ];

export const GENERIC_QUESTION_COMPONENTS = {
  EngagementTaskTextField: 'Text input',
  EngagementTaskCompanyIndustryInput: 'Industry dropdown',
  EngagementTaskYesNoInput: 'Yes/No Checkbox',
  EngagementTaskTextFieldMultiline: 'Text input (multiline)',
  EngagementTaskSelectCurrencyField: 'Currency dropdown',
  EngagementTaskNumberField: 'Number input',
  EngagementTaskNumberFieldOptional: 'Number input (optional)',
  EngagementTaskImportReportInput: 'Import file',
  EngagementTaskMonthYearInput: 'Month/Year Chooser',
  EngagementTaskPercentageNumberField: 'Percentage Input',
  EngagementTaskYesNoMaybeInput: "Yes/No/I don't know Checkbox",
  EngagementTaskCountryAutocompleteField: 'Country dropdown (multi select)',
  EngagementTaskSingleCountryAutocompleteField:
    'Country dropdown (single select)',
};

export class EngagementTaskAnswerInvalidError extends BadDataError {
  status = 500;
  readonly name = 'EngagementTaskAnswerInvalidError';
  constructor(message?: string) {
    super(message);

    Object.setPrototypeOf(this, EngagementTaskAnswerInvalidError.prototype);
  }
}
/**
 * @throws EngagementTaskAnswerInvalidError if answer doesn't match QuestionKey schema
 */
export function validateQuestionKey(
  questionKey: QuestionKeyExtendedType,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  answer: any,
  options?: { raiseError?: boolean }
): boolean {
  if (
    questionKey in QUESTION_SCHEMAS &&
    !QUESTION_SCHEMAS[questionKey as QuestionKey].schema.isValidSync(answer)
  ) {
    if (options?.raiseError) {
      throw new EngagementTaskAnswerInvalidError(
        `The answer ${answer} does not match the required schema for the question key ${questionKey}`
      );
    }
    return false;
  }
  return true;
}

export const COMPLEX_QUESTION_INPUT_COMPONENTS: Array<QuestionComponentId> = [
  QuestionComponentId.EngagementTaskImportReportInput,
  QuestionComponentId.EngagementTaskExpenseCategoryShareMethodInput,
  QuestionComponentId.EngagementTaskPleeEstimationOutputsInput,
  QuestionComponentId.EngagementTaskReductionTargetsInput,
  QuestionComponentId.EngagementTaskReductionInitiativesInput,
  QuestionComponentId.EngagementTaskAcceptedProposeTargetInput,
  QuestionComponentId.EngagementTaskExpensesInput,
  QuestionComponentId.EngagementTaskCeeEstimationOutputsInput,
  QuestionComponentId.EngagementTaskNumberFieldOptional,
  QuestionComponentId.EngagementTaskSfdrUnGlobalCompactComplianceMultiSelect,
  QuestionComponentId.EngagementTaskDiversityInput,
  QuestionComponentId.EngagementTaskEmployeeSurveyKindsMultiSelect,
  QuestionComponentId.EngagementTaskVideo,
  QuestionComponentId.EngagementTaskParentalLeaveInput,
  QuestionComponentId.EngagementTaskSustainabilityInitiativesWithDetailsInput,
  QuestionComponentId.EngagementTaskSustainabilityCommitmentsInput,
  QuestionComponentId.EngagementTaskLearningScopeOne,
  QuestionComponentId.EngagementTaskLearningScopeThree,
  QuestionComponentId.EngagementTaskLearningScopeTwo,
  QuestionComponentId.EngagementTaskMeasurementProjectMultiSelect,
  QuestionComponentId.EngagementTaskNullComponent,
  QuestionComponentId.EngagementTaskFootprintDisclosureOutputs,
  QuestionComponentId.EngagementTaskCeeBuildingsInput,
  QuestionComponentId.EngagementTaskCeeCloudProvidersInput,
  QuestionComponentId.EngagementTaskExpenseCategoryEmissionsInput,
  QuestionComponentId.EngagementTaskModifyProposedTargetInput,
  QuestionComponentId.EngagementTaskNumberFieldWithBooleanSelect,
  QuestionComponentId.EngagementTaskParentalLeaveInput,
];

export function questionComponentIdToInputType(
  componentId: QuestionComponentId
):
  | {
      format: Exclude<
        GQSupplierTableColumnFormat,
        typeof GQSupplierTableColumnFormat.SingleSelect
      >;
    }
  | {
      format: typeof GQSupplierTableColumnFormat.SingleSelect;
      singleSelectOptions: Array<string>;
    }
  | { format: null } {
  switch (componentId) {
    case QuestionComponentId.EngagementTaskCeeBusinessAirMilesInput:
    case QuestionComponentId.EngagementTaskCeeBusinessAirSpendInput:
    case QuestionComponentId.EngagementTaskCeeBusinessGroundMilesInput:
    case QuestionComponentId.EngagementTaskCeeOfficeDaysPerMonthInput:
    case QuestionComponentId.EngagementTaskEmissionAndContextAnswer:
    case QuestionComponentId.EngagementTaskMonthYearInput:
    case QuestionComponentId.EngagementTaskFinanceImpAuditSlider:
    case QuestionComponentId.EngagementTaskNumberField:
    case QuestionComponentId.EngagementTaskPercentageNumberField:
    case QuestionComponentId.EngagementTaskScope3ByCategoryInput:
    case QuestionComponentId.EngagementTaskScopeBoxInput:
      return { format: GQSupplierTableColumnFormat.Number };
    case QuestionComponentId.EngagementTaskCeeCloudInput:
    case QuestionComponentId.EngagementTaskCeeComputationNonCloudInput:
    case QuestionComponentId.EngagementTaskCeeComputationNonCloudInput:
    case QuestionComponentId.EngagementTaskCompanyIndustryInput:
    case QuestionComponentId.EngagementTaskCompanyStructureSelectField:
    case QuestionComponentId.EngagementTaskHelpNeededMeasuringEmissions:
    case QuestionComponentId.EngagementTaskHelpNeededSettingTargets:
    case QuestionComponentId.EngagementTaskHelpNeededSustainabilityProgram:
    case QuestionComponentId.EngagementTaskTextField:
    case QuestionComponentId.EngagementTaskTextFieldMultiline:
    case QuestionComponentId.EngagementTaskCleanPowerDescriptionInput:
      return { format: GQSupplierTableColumnFormat.String };
    case QuestionComponentId.EngagementTaskHaveCommittedSbtiTargetsInput:
    case QuestionComponentId.EngagementTaskHaveMeasuredEmissionsInput:
    case QuestionComponentId.EngagementTaskYesNoInput:
      return {
        format: GQSupplierTableColumnFormat.SingleSelect,
        singleSelectOptions: Object.keys(YesNo),
      };
    case QuestionComponentId.EngagementTaskYesNoMaybeInput:
    case QuestionComponentId.EngagementTaskProviderVerifiedFootprintInput:
    case QuestionComponentId.EngagementTaskSfdrUnGlobalCompactViolationsYesNoMaybeInput:
      return {
        format: GQSupplierTableColumnFormat.SingleSelect,
        singleSelectOptions: Object.keys(YesNoMaybe),
      };
    case QuestionComponentId.EngagementTaskSingleCountryAutocompleteField:
    case QuestionComponentId.EngagementTaskCountryAutocompleteField:
      return {
        format: GQSupplierTableColumnFormat.SingleSelect,
        singleSelectOptions: COUNTRIES_CONST.map((country) => country.name),
      };
    case QuestionComponentId.EngagementTaskSelectCurrencyField:
    case QuestionComponentId.EngagementTaskCeeBusinessAirSpendCurrencyInput:
      return {
        format: GQSupplierTableColumnFormat.SingleSelect,
        singleSelectOptions: Object.keys(CURRENCY_MAP),
      };
    case QuestionComponentId.EngagementTaskSbtEngagementStatusInput:
    case QuestionComponentId.EngagementTaskShareMethodInput:
      return {
        format: GQSupplierTableColumnFormat.SingleSelect,
        singleSelectOptions: Object.keys(GQCompanySbtCommitmentStage),
      };
    case QuestionComponentId.EngagementTaskHaveMeasuredEmissionsSbtGhgV2Input:
    case QuestionComponentId.EngagementTaskHaveMeasuredEmissionsV2Input:
    case QuestionComponentId.EngagementTaskHaveMeasuredEmissionsV2MustMeasureInput:
      return {
        format: GQSupplierTableColumnFormat.SingleSelect,
        singleSelectOptions: Object.keys(HaveMeasuredEmissionsV2),
      };
    case QuestionComponentId.EngagementTaskSasbSelectField:
    case QuestionComponentId.EngagementTaskPrimarySectorOperationsSelectField:
      return {
        format: GQSupplierTableColumnFormat.SingleSelect,
        singleSelectOptions: Object.keys(GQSasbSectorType),
      };
    case QuestionComponentId.EngagementTaskAllocateEmissionsMethodInput:
      return {
        format: GQSupplierTableColumnFormat.SingleSelect,
        singleSelectOptions: Object.values(AllocateEmissionsMethod),
      };
    case QuestionComponentId.EngagementTaskAllocateEmissionsMethodV2Input:
      return {
        format: GQSupplierTableColumnFormat.SingleSelect,
        singleSelectOptions: Object.values(AllocateEmissionsMethodV2),
      };
    case QuestionComponentId.EngagementTaskCarbonMeasurementPlanInput:
      return {
        format: GQSupplierTableColumnFormat.SingleSelect,
        singleSelectOptions: Object.values(CarbonMeasurementPlan),
      };
    case QuestionComponentId.EngagementTaskCompanyGrowthStageSelectField:
      return {
        format: GQSupplierTableColumnFormat.SingleSelect,
        singleSelectOptions: COMPANY_GROWTH_STAGES,
      };
    case QuestionComponentId.EngagementTaskDecarbonizationPlanSelectField:
      return {
        format: GQSupplierTableColumnFormat.SingleSelect,
        singleSelectOptions: DECARBONIZATION_PLAN_SELECT_OPTIONS,
      };
    case QuestionComponentId.EngagementTaskEvaluateProposedTargetInput:
      return {
        format: GQSupplierTableColumnFormat.SingleSelect,
        singleSelectOptions: Object.keys(AcceptDeclineModify),
      };
    case QuestionComponentId.EngagementTaskGhgMethodologySelectField:
      return {
        format: GQSupplierTableColumnFormat.SingleSelect,
        singleSelectOptions: GHG_METHODOLOGY_TYPES,
      };
    case QuestionComponentId.EngagementTaskHasEnvironmentalTeamInput:
      return {
        format: GQSupplierTableColumnFormat.SingleSelect,
        singleSelectOptions: Object.keys(SustainabilityTeamSize),
      };
    case QuestionComponentId.EngagementTaskLongTermNetZeroSelectField:
      return {
        format: GQSupplierTableColumnFormat.SingleSelect,
        singleSelectOptions: ENGAGEMENT_TASK_LONG_TERM_NET_ZERO_SELECT_OPTIONS,
      };
    case QuestionComponentId.EngagementTaskPrimaryIndustryOfOperationsSelectField:
      return {
        format: GQSupplierTableColumnFormat.SingleSelect,
        singleSelectOptions: Object.keys(GQSasbIndustryType),
      };
    case QuestionComponentId.EngagementTaskSasbIndustrySelectField:
      return {
        format: GQSupplierTableColumnFormat.SingleSelect,
        singleSelectOptions: Object.keys(GQSasbIndustryType),
      };
    case QuestionComponentId.EngagementTaskScope1MethodologySelectField:
      return {
        format: GQSupplierTableColumnFormat.SingleSelect,
        singleSelectOptions: Object.keys(GQEdciScope1MethodologyOption),
      };
    case QuestionComponentId.EngagementTaskScope2MethodologySelectField:
      return {
        format: GQSupplierTableColumnFormat.SingleSelect,
        singleSelectOptions: Object.keys(GQEdciScope2MethodologyOption),
      };
    case QuestionComponentId.EngagementTaskShareMethodV2Input:
      return {
        format: GQSupplierTableColumnFormat.SingleSelect,
        singleSelectOptions: Object.keys(ShareMethodV2),
      };
    case QuestionComponentId.EngagementTaskShortTermReductionTargetSelectField:
      return {
        format: GQSupplierTableColumnFormat.SingleSelect,
        singleSelectOptions:
          ENGAGEMENT_TASK_SHORT_TERM_REDUCTION_TARGETS_SELECT_OPTIONS,
      };
    case QuestionComponentId.EngagementTaskLearningScienceBasedTargetsDefinition3:
    case QuestionComponentId.EngagementTaskLearningScienceBasedTargetsDevelopTargetKeyQuestions:
    case QuestionComponentId.EngagementTaskLearningScienceBasedTargetsNumberOfCompaniesCommitted:
    case QuestionComponentId.EngagementTaskMeasuringEmissionsSpectrum:
    case QuestionComponentId.EngagementTaskLearningMarketTrends:
    case QuestionComponentId.EngagementTaskLearningOperationalCosts:
    case QuestionComponentId.EngagementTaskLearningRisk:
    case QuestionComponentId.EngagementTaskLearningScienceBasedTargetsClimateScienceBasics:
    case QuestionComponentId.EngagementTaskLearningLaunchingAClimateProgramAmbitionLevelSpectrum:
    case QuestionComponentId.EngagementTaskLearningLaunchingAClimateProgramLongTerm:
    case QuestionComponentId.EngagementTaskLearningLaunchingAClimateProgramSupport:
    case QuestionComponentId.EngagementTaskLearningLaunchingAClimateProgramVoluntaryReporting:
    case QuestionComponentId.EngagementTaskLearningEmployees:
    case QuestionComponentId.EngagementTaskLearningEngagingEmployeesTactics:
    case QuestionComponentId.EngagementTaskLearningEngagingLeadershipTactics:
    case QuestionComponentId.EngagementTaskLearningIdentifyingStakeholders:
    case QuestionComponentId.EngagementTaskLearningCorporateScienceBasedTargetsStep2:
    case QuestionComponentId.EngagementTaskLearningCorporateScienceBasedTargetsStep3:
    case QuestionComponentId.EngagementTaskLearningEmissionsFactors:
    case QuestionComponentId.EngagementTaskLearningCorporateScienceBasedTargetsReduceYourEmissions:
    case QuestionComponentId.EngagementTaskFootprintShareSingleSelect:
    case QuestionComponentId.EngagementTaskLearningBusinessActivities:
    case QuestionComponentId.EngagementTaskLearningCarbonAccounting:
    case QuestionComponentId.EngagementTaskLearningCorporateClimateActionBenefits:
    case QuestionComponentId.EngagementTaskFinanceImpSlider:
    case QuestionComponentId.EngagementTaskFinanceImpValue:
    case QuestionComponentId.EngagementTaskFinanceImpRecap:
    case QuestionComponentId.EngagementTaskAcceptedProposeTargetInput:
    case QuestionComponentId.EngagementTaskImportReportInput:
    case QuestionComponentId.EngagementTaskExpenseCategoryShareMethodInput:
    case QuestionComponentId.EngagementTaskExpenseCategoryEmissionsInput:
    case QuestionComponentId.EngagementTaskPleeEstimationOutputsInput:
    case QuestionComponentId.EngagementTaskReductionTargetsInput:
    case QuestionComponentId.EngagementTaskReductionInitiativesInput:
    case QuestionComponentId.EngagementTaskExpensesInput:
    case QuestionComponentId.EngagementTaskCeeEstimationOutputsInput:
    case QuestionComponentId.EngagementTaskNumberFieldOptional:
    case QuestionComponentId.EngagementTaskSfdrUnGlobalCompactComplianceMultiSelect:
    case QuestionComponentId.EngagementTaskDiversityInput:
    case QuestionComponentId.EngagementTaskEmployeeSurveyKindsMultiSelect:
    case QuestionComponentId.EngagementTaskVideo:
    case QuestionComponentId.EngagementTaskSustainabilityInitiativesWithDetailsInput:
    case QuestionComponentId.EngagementTaskSustainabilityCommitmentsInput:
    case QuestionComponentId.EngagementTaskLearningScopeOne:
    case QuestionComponentId.EngagementTaskLearningScopeThree:
    case QuestionComponentId.EngagementTaskLearningScopeTwo:
    case QuestionComponentId.EngagementTaskMeasurementProjectMultiSelect:
    case QuestionComponentId.EngagementTaskNullComponent:
    case QuestionComponentId.EngagementTaskFootprintDisclosureOutputs:
    case QuestionComponentId.EngagementTaskCeeBuildingsInput:
    case QuestionComponentId.EngagementTaskCeeCloudProvidersInput:
    case QuestionComponentId.EngagementTaskModifyProposedTargetInput:
    case QuestionComponentId.EngagementTaskNumberFieldWithBooleanSelect:
    case QuestionComponentId.EngagementTaskParentalLeaveInput:
      return { format: null };
    default:
      assertNever(componentId);
  }
}
