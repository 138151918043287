// A light-weight data type for dealing with month-granularity dates. Avoids
// some of the performance issues and timezone footguns that come with using
// full-blown Luxon DateTimes.

import invariant from 'invariant';
import { Interval } from 'luxon';
import assertNever from './assertNever';
import normalizeNegativeZero from './normalizeNegativeZero';
import type { Opaque } from './Opaque';
import { EqualityFunction } from './samesies';
import { UserInputError } from '../errors/UserInputError';
import { SupportedLocale } from '@watershed/intl/constants';
import { formatDateRange } from '@watershed/intl/formatters';
import { i18n } from '@watershed/intl';

export type YearMonth = Opaque<number, 'YearMonth'>;

export interface YearMonthObject {
  year: number;
  month: number;
}

export type YearMonthUnit = 'year' | 'month';

const ymISORegex = /\d{4}-\d{2}-\d{2}/;

const MIN_YEARMONTH_YEAR = 1500;
const MAX_YEARMONTH_YEAR = 2999;

export const YM = Object.freeze({
  make(year: number, month: number = 1): YearMonth {
    return YM.setMonth(YM.setYear(0 as YearMonth, year), month);
  },
  fromNumber(n: number): YearMonth {
    const year = Math.floor(n / 100);
    const month = n % 100;
    // We use make to take advantage of all the checks.
    return YM.make(year, month);
  },
  fromObject({ year, month }: YearMonthObject): YearMonth {
    return YM.make(year, month);
  },
  fromYYYYMM(yyyymm: string): YearMonth {
    invariant(
      yyyymm.length === 6,
      `Must be in format YYYYMM. Received ${yyyymm}`
    );

    const year = parseInt(yyyymm.slice(0, 4), 10);
    const month = parseInt(yyyymm.slice(4), 10);

    invariant(
      month <= 12 && month >= 1,
      `Month must be between 1 & 12, Value: ${month}`
    );

    return YM.make(year, month);
  },
  fromISO(iso: string): YearMonth {
    invariant(
      ymISORegex.test(iso),
      `ISO date must be YYYY-MM-DD. Received ${iso}`
    );
    const year = parseInt(iso.slice(0, 4), 10);
    const month = parseInt(iso.slice(5, 7), 10);
    //const day = parseInt(iso.slice(8, 10), 10);
    //invariant(day === 1, `YearMonth must have day = 1`);
    return YM.make(year, month);
  },
  fromJSDate(date: Date): YearMonth {
    return YM.make(date.getFullYear(), date.getMonth() + 1);
  },
  fromEpochSeconds(epoch: number): YearMonth {
    const date = new Date(epoch * 1000);
    return YM.make(date.getUTCFullYear(), date.getUTCMonth() + 1);
  },
  fromEpochMilliseconds(epoch: number): YearMonth {
    const date = new Date(epoch);
    return YM.make(date.getUTCFullYear(), date.getUTCMonth() + 1);
  },
  fromUtcTimestamp(timestamp: number): YearMonth {
    const date = new Date(timestamp);
    return YM.make(date.getUTCFullYear(), date.getUTCMonth() + 1);
  },
  now(): YearMonth {
    return YM.fromJSDate(new Date());
  },

  toNumber(ym: YearMonth): number {
    return this.year(ym) * 100 + this.month(ym);
  },
  toObject(ym: YearMonth): YearMonthObject {
    return { year: YM.year(ym), month: YM.month(ym) };
  },
  toYYYYMM(ym: YearMonth): string {
    return ym.toString();
  },
  toISO(ym: YearMonth): string {
    const str = (ym + 1_000_000).toString();
    return `${str.slice(1, 5)}-${str.slice(5, 7)}-01`;
  },
  toFormat(
    ym: YearMonth,
    {
      month = 'short',
      year = 'numeric',
      locale = i18n.locale as SupportedLocale,
    }: {
      month?: Intl.DateTimeFormatOptions['month'];
      year?: Intl.DateTimeFormatOptions['year'];
      locale?: SupportedLocale;
    } = {}
  ): string {
    const monthStr = new Intl.DateTimeFormat(locale, {
      month,
    }).format(YM.toJSDate(ym));
    const yearStr =
      year === 'numeric'
        ? YM.year(ym)
        : `’${new Intl.DateTimeFormat(locale, {
            year,
          }).format(YM.toJSDate(ym))}`;
    return `${monthStr} ${yearStr}`;
  },
  toJSDate(ym: YearMonth): Date {
    const { year, month } = YM.toObject(ym);
    return new Date(year, month - 1);
  },
  toEpochSeconds(ym: YearMonth): number {
    const { year, month } = YM.toObject(ym);
    return Date.UTC(year, month - 1) / 1000;
  },
  toEpochMilliseconds(ym: YearMonth): number {
    const { year, month } = YM.toObject(ym);
    return Date.UTC(year, month - 1);
  },
  isValidYearMonthInteger(candidate: number): boolean {
    return (
      Number.isInteger(candidate) &&
      candidate > MIN_YEARMONTH_YEAR * 100 &&
      candidate <= MAX_YEARMONTH_YEAR * 100 + 1 &&
      candidate % 100 > 0 &&
      candidate % 100 <= 12
    );
  },
  plus(ym: YearMonth, n: number, unit: YearMonthUnit = 'month'): YearMonth {
    invariant(n === (n | 0), 'n must be an integer');
    const oldYear = this.year(ym);
    const oldMonth = this.month(ym);
    switch (unit) {
      case 'month': {
        // Like a timestamp, but number of months since year 0.
        // Simplifies dealing with month over/underflow.
        const oldMonthstamp = oldYear * 12 + (oldMonth - 1);

        const newMonthstamp = oldMonthstamp + n;
        const newYear = Math.floor(newMonthstamp / 12);
        const newMonth = (newMonthstamp % 12) + 1;

        return this.make(newYear, newMonth);
      }
      case 'year': {
        const newYear = oldYear + n;

        return this.make(newYear, oldMonth);
      }
      default:
        assertNever(unit);
    }
  },
  minus(ym: YearMonth, n: number, unit: YearMonthUnit = 'month'): YearMonth {
    return YM.plus(ym, -n, unit);
  },
  next(ym: YearMonth, unit: YearMonthUnit = 'month'): YearMonth {
    return YM.plus(ym, 1, unit);
  },
  month(ym: YearMonth): number {
    return ym % 100;
  },
  year(ym: YearMonth): number {
    return Math.floor(ym / 100);
  },
  setMonth(ym: YearMonth, month: number): YearMonth {
    invariant(month >= 1, 'month is too small (received %s)', month);
    invariant(month <= 12, 'month is too large (received %s)', month);
    return (ym - (ym % 100) + month) as YearMonth;
  },
  setYear(ym: YearMonth, year: number): YearMonth {
    invariant(
      year >= MIN_YEARMONTH_YEAR,
      'year is implausibly small (received %s)',
      year
    );
    invariant(
      year <= MAX_YEARMONTH_YEAR,
      'year is implausibly large (received %s)',
      year
    );
    return ((ym % 100) + year * 100) as YearMonth;
  },
  lastDayOfMonth(ym: YearMonth): number {
    // JS Date month is 0-indexed, so the 0th day is the last day of the prior month
    return new Date(YM.year(ym), YM.month(ym), 0).getDate();
  },
  // Returns the quarter number for the given year month.
  // Jan-Mar: 1
  // Apr-Jun: 2
  // Jul-Sep: 3
  // Oct-Dec: 4
  quarter(ym: YearMonth): number {
    return Math.floor((this.month(ym) - 1) / 3) + 1;
  },

  // Returns the fiscal year for the given year month.
  fiscalYearOf(ym: YearMonth, fiscalYearStartMonth: number): number {
    if (fiscalYearStartMonth === 1 || YM.month(ym) < fiscalYearStartMonth) {
      return YM.year(ym);
    } else {
      return YM.year(ym) + 1;
    }
  },

  // This returns the start YearMonth of the quarter containing the given YearMonth
  // given the provided fiscal year start month
  // So if the fiscal year starts in February, the fiscal quarter floor for June is May
  fiscalQuarterFloor(ym: YearMonth, fiscalYearStartMonth: number): YearMonth {
    let offset = (YM.month(ym) % 3) - (fiscalYearStartMonth % 3);
    if (offset < 0) {
      offset = offset + 3;
    }
    return YM.minus(ym, offset);
  },

  toYearString(
    ym: YearMonth,
    opts?: {
      locale?: SupportedLocale;
      format?: Intl.DateTimeFormatOptions['year'];
    }
  ): string {
    const locale = opts?.locale ?? i18n.locale;

    const labelFmtr = new Intl.DateTimeFormat(locale, {
      year: opts?.format ?? 'numeric',
    });
    const yearLabel = labelFmtr.format(YM.toJSDate(ym));
    return yearLabel;
  },

  yearFloor(ym: YearMonth): YearMonth {
    const year = YM.year(ym);
    return YM.make(year, 1);
  },
  yearCeil(ym: YearMonth): YearMonth {
    const { year, month } = YM.toObject(ym);
    if (month === 1) {
      return ym;
    }
    return YM.make(year + 1, 1);
  },
  // Returns the number of full units between the two times (i.e., ym - other)
  diff(ym: YearMonth, other: YearMonth, unit: YearMonthUnit = 'month'): number {
    const oldYear = this.year(ym);
    const oldMonth = this.month(ym);
    const oldOtherYear = this.year(other);
    const oldOtherMonth = this.month(other);
    const monthDiff =
      (oldYear - oldOtherYear) * 12 + (oldMonth - oldOtherMonth);
    switch (unit) {
      case 'month':
        return monthDiff;
      case 'year':
        return normalizeNegativeZero(
          monthDiff > 0 ? Math.floor(monthDiff / 12) : Math.ceil(monthDiff / 12)
        );
      default:
        assertNever(unit);
    }
  },
  min(...yms: Array<YearMonth>) {
    return this.fromNumber(Math.min(...yms.map((ym) => this.toNumber(ym))));
  },
  max(...yms: Array<YearMonth>) {
    return this.fromNumber(Math.max(...yms.map((ym) => this.toNumber(ym))));
  },
  clamp(start: YearMonth, end: YearMonth, value: YearMonth) {
    return YM.min(YM.max(start, value), end);
  },

  infinity(): YearMonth {
    return YM.make(MAX_YEARMONTH_YEAR);
  },
  negativeInfinity(): YearMonth {
    return YM.make(MIN_YEARMONTH_YEAR);
  },

  toInclusiveEnd(ym: YearMonth): YearMonthInclusiveEnd {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new YearMonthInclusiveEnd(ym);
  },
});

/**
 * When a `YearMonth` semantically represents an "exclusive end", the display
 * value will be the previous `YearMonth`. This helper class aids in displaying
 * such values. See the PR for more details, but the goal with this class is to
 * isolate the scope of these "inclusive end" `YearMonth`s to display logic - we
 * intentionally do not expose the rest of the `YearMonth` API on this class.
 *
 * See this RFC for more details:
 * https://www.notion.so/RFC-Representing-time-0041d528711943ae9c5f04daf8127d8c
 */
export class YearMonthInclusiveEnd {
  readonly displayValue: YearMonth;

  constructor(ymExclusiveEnd: YearMonth) {
    this.displayValue = YM.minus(ymExclusiveEnd, 1);
  }

  toFormat(opts?: Parameters<typeof YM.toFormat>[1]): string {
    return YM.toFormat(this.displayValue, opts);
  }

  toISO(): string {
    return YM.toISO(this.displayValue);
  }
}

const ymIntervalURLRegex = /\d{6}-\d{6}/;

// YMInterval is always inclusive of start and exclusive of end
export class YMInterval {
  readonly start: YearMonth;
  readonly end: YearMonth;

  static fromURLString(urlString: string): YMInterval {
    invariant(
      ymIntervalURLRegex.test(urlString),
      `ISO date interval must be YYYYMM-YYYYMM. Received ${urlString}`
    );
    const [startString, endString] = urlString.split('-');
    const start = YM.fromYYYYMM(startString);
    const end = YM.fromYYYYMM(endString);
    return new YMInterval(start, end);
  }

  static forYear(year: number): YMInterval {
    return new YMInterval(YM.make(year, 1), YM.make(year + 1, 1));
  }

  // Note: Fiscal years *END* in the specified year
  static forFiscalYear(year: number, fiscalYearStartMonth: number): YMInterval {
    const startYear = fiscalYearStartMonth === 1 ? year : year - 1;
    return new YMInterval(
      YM.make(startYear, fiscalYearStartMonth),
      YM.make(startYear + 1, fiscalYearStartMonth)
    );
  }

  static trailing12Months(): YMInterval {
    return YMInterval.trailing12MonthsFrom(YM.now());
  }

  static trailing12MonthsFrom(end: YearMonth): YMInterval {
    return new YMInterval(YM.minus(end, 12), end);
  }

  static fromInterval(interval: Interval): YMInterval {
    const startDate = interval.start.toJSDate();
    const endDate = interval.end.toJSDate();
    return new YMInterval(YM.fromJSDate(startDate), YM.fromJSDate(endDate));
  }

  static fromObject(obj: { start: YearMonth; end: YearMonth }): YMInterval {
    return new YMInterval(obj.start, obj.end);
  }

  static fromNumber(start: number, end: number): YMInterval {
    return new YMInterval(YM.fromNumber(start), YM.fromNumber(end));
  }

  constructor(start: YearMonth, end: YearMonth) {
    UserInputError.invariant(start <= end, 'start is after end');
    this.start = start;
    this.end = end;
  }

  contains(ym: YearMonth): boolean {
    return this.start <= ym && ym < this.end;
  }
  containsInterval(other: YMInterval): boolean {
    return this.start <= other.start && other.end <= this.end;
  }
  intersect(other: YMInterval): YMInterval | null {
    if (this.start >= other.end || other.start >= this.end) {
      return null;
    }
    return new YMInterval(
      YM.max(this.start, other.start),
      YM.min(this.end, other.end)
    );
  }
  union(other: YMInterval): YMInterval {
    return new YMInterval(
      YM.min(this.start, other.start),
      YM.max(this.end, other.end)
    );
  }
  length(unit: YearMonthUnit): number {
    return YM.diff(this.end, this.start, unit);
  }
  *iter(unit: YearMonthUnit): Generator<YearMonth, void, unknown> {
    for (
      let cursor = this.start;
      cursor < this.end;
      cursor = YM.next(cursor, unit)
    ) {
      yield cursor;
    }
  }
  map<T>(unit: YearMonthUnit, fn: (ym: YearMonth) => T): Array<T> {
    const values = [];
    for (const ym of this.iter(unit)) {
      values.push(fn(ym));
    }
    return values;
  }

  private listOfYearMonths(unit: YearMonthUnit) {
    const container = [];
    for (const time of this.iter(unit)) {
      container.push(time);
    }
    return container;
  }

  months(): Array<YearMonth> {
    return this.listOfYearMonths('month');
  }

  years(): Array<YearMonth> {
    return this.listOfYearMonths('year');
  }

  // Returns any year that the interval is in (partially or completely)
  allPresentYears(): Array<number> {
    const years = new Set<number>(
      this.months().map((yearMonth) => YM.year(yearMonth))
    );
    return Array.from(years).sort();
  }

  // Returns all fiscal year intervals that are partially or completely in the interval
  allFiscalYears(fiscalYearStartMonth: number): Array<number> {
    const years = new Set<number>(
      this.months().map((yearMonth) =>
        YM.fiscalYearOf(yearMonth, fiscalYearStartMonth)
      )
    );
    return Array.from(years).sort();
  }

  // Returns the number of complete years (jan - dec) in the interval
  containedYears(): Array<number> {
    const potentiallyContainedYears = this.allPresentYears();
    if (potentiallyContainedYears.length === 0) {
      return [];
    }
    const startYear = potentiallyContainedYears[0];
    if (!this.contains(YM.make(startYear, 1))) {
      // If we don't have the January of the first year, we don't
      // have the complete year. That means that we don't fully contain
      // that year.
      potentiallyContainedYears.shift();
    }

    if (potentiallyContainedYears.length === 0) {
      return [];
    }
    const lastYear =
      potentiallyContainedYears[potentiallyContainedYears.length - 1];
    if (!this.contains(YM.make(lastYear, 12))) {
      // If we don't have the December of the last year, we don't
      // have the complete year. That means that we don't fully contain
      // that year.
      potentiallyContainedYears.pop();
    }
    return potentiallyContainedYears;
  }

  // Returns the calendar years that are partially included in the
  // beginning and end of this interval.
  partialYears(): Array<number> {
    const allPresentYears = this.allPresentYears();
    const startYear = allPresentYears[0];
    const partialYears = [];
    if (!this.contains(YM.make(startYear, 1))) {
      // If we don't have the January of the first year, we don't
      // have the complete year. That means that we have a partial first year.
      partialYears.push(startYear);
    }

    const lastYear = allPresentYears[allPresentYears.length - 1];
    if (!this.contains(YM.make(lastYear, 12))) {
      // If we don't have the December of the last year, we don't
      // have the complete year. That means that we have a partial last year.
      partialYears.push(lastYear);
    }
    return partialYears;
  }

  // Returns the fiscal years that are partially included in the beginning and
  // end of this interval. Note that fiscal years end in a given year. For
  // example, if fiscal year starts in April, FY2022 covers [2021-04-01, 2022-04-01).
  partialFiscalYears(fiscalYearStartMonth: number): Array<number> {
    const allFiscalYears = this.allFiscalYears(fiscalYearStartMonth);
    const partialYears = [];

    const startYear = allFiscalYears[0];
    const startYearMonth1 =
      fiscalYearStartMonth === 1
        ? YM.make(startYear, fiscalYearStartMonth)
        : YM.make(startYear - 1, fiscalYearStartMonth);

    // If we don't have the first month of the first fiscal year, we don't have
    // a complete fiscal year. That means we have a partial first fiscal year.
    if (!this.contains(startYearMonth1)) {
      partialYears.push(startYear);
    }

    const lastYear = allFiscalYears[allFiscalYears.length - 1];
    const lastYearMonth1 =
      fiscalYearStartMonth === 1
        ? YM.make(lastYear, fiscalYearStartMonth)
        : YM.make(lastYear - 1, fiscalYearStartMonth);
    const lastYearMonth12 = YM.plus(lastYearMonth1, 11, 'month');

    // If we don't have the last month of the last fiscal year, we don't have a
    // complete fiscal year. That means we have a partial last fiscal year.
    if (!this.contains(lastYearMonth12)) {
      partialYears.push(lastYear);
    }

    return partialYears;
  }

  /**
   * Returns a sorted list of fiscal years that are fully contained in the
   * interval.
   */
  public containedFiscalYears(fiscalYearStartMonth: number): Array<number> {
    const allFiscalYears = this.allFiscalYears(fiscalYearStartMonth);
    const partialFiscalYears = this.partialFiscalYears(fiscalYearStartMonth);
    return allFiscalYears.filter((year) => !partialFiscalYears.includes(year));
  }

  toISO(): string {
    return `${YM.toISO(this.start)}/${YM.toISO(this.end)}`;
  }

  /**
   * Old version of toFormat that doesn't make use of localized range formatting
   * @deprecated use toFormat instead
   */
  toLegacyFormat(
    format: Intl.DateTimeFormatOptions['month'] = 'short',
    opts?: {
      separatorInput?: string;
      locale?: SupportedLocale;
    }
  ): string {
    const locale = opts?.locale ?? (i18n.locale as SupportedLocale);

    const formatter = new Intl.DateTimeFormat(locale, { month: format });
    const separator = opts?.separatorInput ?? (format === 'long' ? ' – ' : '–');
    if (
      this.start === this.end ||
      this.start === YM.minus(this.end, 1, 'month')
    ) {
      return YM.toFormat(this.start, { month: format, locale });
    } else if (
      YM.year(this.start) === YM.year(YM.minus(this.end, 1, 'month'))
    ) {
      const startMonth = formatter.format(YM.toJSDate(this.start));
      const endMonth = formatter.format(
        YM.toJSDate(YM.minus(this.end, 1, 'month'))
      );
      return `${startMonth}${separator}${endMonth} ${YM.year(this.start)}`;
    } else if (this.start === YM.negativeInfinity()) {
      const incEnd = YM.minus(this.end, 1, 'month');
      return `${separator}${YM.toFormat(incEnd, { month: format, locale })}`;
    } else if (this.end === YM.infinity()) {
      return `${YM.toFormat(this.start, {
        month: format,
        locale,
      })}${separator}`;
    } else {
      const incEnd = YM.minus(this.end, 1, 'month');
      return `${YM.toFormat(this.start, {
        month: format,
        locale,
      })}${separator}${YM.toFormat(incEnd, { month: format, locale })}`;
    }
  }

  toFormat(
    format: Intl.DateTimeFormatOptions['month'] = 'short',
    opts?: {
      locale?: SupportedLocale;
    }
  ): string {
    const locale = opts?.locale ?? (i18n.locale as SupportedLocale);

    if (
      this.start === this.end ||
      this.start === YM.minus(this.end, 1, 'month')
    ) {
      // Special case for when the interval is only one month long
      return YM.toFormat(this.start, {
        year: 'numeric',
        month: format,
        locale,
      });
    } else if (
      YM.year(this.start) === YM.year(YM.minus(this.end, 1, 'month'))
    ) {
      return formatDateRange(
        YM.toJSDate(this.start),
        YM.toJSDate(YM.minus(this.end, 1, 'month')),
        { year: 'numeric', month: format, locale }
      );
    } else if (this.start === YM.negativeInfinity()) {
      return formatDateRange(
        undefined,
        YM.toJSDate(YM.minus(this.end, 1, 'month')),
        { year: 'numeric', month: format, locale }
      );
    } else if (this.end === YM.infinity()) {
      return formatDateRange(YM.toJSDate(this.start), undefined, {
        year: 'numeric',
        month: format,
        locale,
      });
    } else {
      return formatDateRange(
        YM.toJSDate(this.start),
        YM.toJSDate(YM.minus(this.end, 1, 'month')),
        { year: 'numeric', month: format, locale }
      );
    }
  }

  toFiscalYearString(
    fiscalYearStartMonth: number,
    { condensed, isPartial }: { condensed: boolean; isPartial: boolean } = {
      condensed: false,
      isPartial: false,
    }
  ): string {
    const lengthMonths = this.length('month');

    if (lengthMonths === 12) {
      invariant(
        fiscalYearStartMonth === YM.month(this.start),
        'Invalid interval: Year not aligned with fiscal year'
      );
      const fiscalYear = YM.year(YM.minus(this.end, 1, 'month'));
      return `FY${String(fiscalYear).slice(-2)}${
        isPartial ? ' (partial)' : ''
      }`;
    }
    if (lengthMonths === 3) {
      let quarterOffset = (YM.month(this.start) - fiscalYearStartMonth) / 3;
      invariant(
        quarterOffset === Math.round(quarterOffset),
        'Invalid interval: Quarter not aligned with fiscal year'
      );
      if (quarterOffset < 0) {
        quarterOffset += 4;
      }
      const quarter = quarterOffset + 1;
      if (condensed) return `Q${quarter}`;
      const fiscalYear = YM.year(
        YM.minus(this.end, 1 - 3 * (4 - quarter), 'month')
      );
      const baseString = `FY${String(fiscalYear).slice(-2)}`;
      return `Q${quarter}-${baseString}`;
    }
    throw new Error(
      `Invalid interval length ${lengthMonths} for toFiscalYearString`
    );
  }

  toURLString(): string {
    return `${YM.toYYYYMM(this.start)}-${YM.toYYYYMM(this.end)}`;
  }

  shrinkToYear(): YMInterval {
    return new YMInterval(YM.yearCeil(this.start), YM.yearFloor(this.end));
  }
  growToYear(): YMInterval {
    return new YMInterval(YM.yearFloor(this.start), YM.yearCeil(this.end));
  }
  equals(other: YMInterval): boolean {
    return this.start === other.start && this.end === other.end;
  }
  [EqualityFunction](other: YMInterval): boolean {
    return this.equals(other);
  }
  plus(n: number, unit: YearMonthUnit = 'month'): YMInterval {
    return new YMInterval(
      YM.plus(this.start, n, unit),
      YM.plus(this.end, n, unit)
    );
  }
  minus(n: number, unit: YearMonthUnit = 'month'): YMInterval {
    return new YMInterval(
      YM.minus(this.start, n, unit),
      YM.minus(this.end, n, unit)
    );
  }
  isFullCalendarYear(): boolean {
    return this.equals(YMInterval.forYear(YM.year(this.start)));
  }
  toInclusiveEnd(): YMIntervalInclusiveEnd {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return new YMIntervalInclusiveEnd(this.start, YM.minus(this.end, 1));
  }
  toJSDates(): { start: Date; end: Date } {
    return {
      start: YM.toJSDate(this.start),
      end: YM.toJSDate(this.end),
    };
  }
  clampToNewStart(start: YearMonth): YMInterval {
    return new YMInterval(start, YM.max(YM.plus(start, 1), this.end));
  }
  clampToNewEnd(end: YearMonth): YMInterval {
    return new YMInterval(YM.min(YM.minus(end, 1), this.start), end);
  }
}

/**
 * We prefer to use `YMInterval` (exclusive end) as much as possible. At times,
 * especially when showing dates to users, we need to convert from exclusive to
 * inclusive and vice versa. We prefer to only use `YMIntervalInclusiveEnd` as
 * close to the place of data display / data input as possible, and everywhere
 * else use `YMInterval`.
 *
 * See this RFC for more details:
 * https://www.notion.so/RFC-Representing-time-0041d528711943ae9c5f04daf8127d8c
 */
export class YMIntervalInclusiveEnd {
  readonly start: YearMonth;
  readonly end: YearMonth;

  constructor(start: YearMonth, end: YearMonth) {
    UserInputError.invariant(start <= end, 'start is after end');
    this.start = start;
    this.end = end;
  }

  toExclusiveEnd(): YMInterval {
    return new YMInterval(this.start, YM.plus(this.end, 1));
  }
}

export const COVID = new YMInterval(YM.make(2020, 3), YM.make(2022, 1));

export class FiscalYear extends YMInterval {
  readonly fiscalMonthOffset: number;

  constructor(start: YearMonth, end: YearMonth) {
    invariant(YM.year(end) - YM.year(start) === 1, 'must be 1 year long');
    invariant(YM.month(end) === YM.month(start), 'must be the same month');
    super(start, end);
    this.fiscalMonthOffset = YM.month(end);
  }

  get interval(): YMInterval {
    return new YMInterval(this.start, this.end);
  }

  static fromStartYearMonth(start: YearMonth): FiscalYear {
    return new FiscalYear(start, YM.plus(start, 1, 'year'));
  }

  static fromEndYearMonth(endExclusive: YearMonth): FiscalYear {
    return new FiscalYear(YM.minus(endExclusive, 1, 'year'), endExclusive);
  }

  static displayLabelForYearStartingAtYearMonth(
    yearMonth: YearMonth,
    opts?: { short?: boolean; locale?: SupportedLocale }
  ): string {
    return FiscalYear.fromStartYearMonth(yearMonth).fiscalYearDisplayLabel(
      opts
    );
  }

  fiscalYearDisplayLabel(opts?: {
    short?: boolean;
    locale?: SupportedLocale;
  }): string {
    const locale = opts?.locale ?? (i18n.locale as SupportedLocale);

    // 2020-01 - 2021-01 should just be 2020
    if (this.fiscalMonthOffset === 1) {
      return YM.toYearString(this.start, {
        locale,
        format: 'numeric',
      });
    }

    if (opts?.short) {
      const yearShort = YM.toYearString(this.end, {
        locale: opts?.locale,
        format: '2-digit',
      });
      // TODO(LOC-186): Instrumenting this string will cause temporal-worker to fail build
      return `FY${yearShort}`;
    }
    // could add params to this in the future!
    // Feb 2020 - Jan 2021
    return this.toFormat(undefined, opts);
  }

  static findStartingFiscalYearMonthForYearMonth(
    yearMonth: YearMonth,
    alignedMonth: number
  ): YearMonth {
    // If our aligned month is 2, and our yearMonth is 5/6/7
    if (YM.month(yearMonth) >= alignedMonth) {
      return YM.make(YM.year(yearMonth), alignedMonth);
    }
    return YM.make(YM.year(yearMonth) - 1, alignedMonth);
  }

  static findContainedFiscalYearSequence(
    interval: YMInterval,
    fiscalMonth: number
  ): YMInterval {
    const start = FiscalYear.findStartingFiscalYearMonthForYearMonth(
      YM.plus(interval.start, 11, 'month'),
      fiscalMonth
    );
    const end = FiscalYear.findStartingFiscalYearMonthForYearMonth(
      interval.end,
      fiscalMonth
    );
    invariant(YM.month(start) === fiscalMonth, 'should be true');
    invariant(YM.month(end) === fiscalMonth, 'should be true');
    return new YMInterval(start, end);
  }

  static findSurroundingFiscalYearInterval(
    interval: YMInterval,
    fiscalMonth: number
  ): YMInterval {
    const start = FiscalYear.findStartingFiscalYearMonthForYearMonth(
      interval.start,
      fiscalMonth
    );
    const end = FiscalYear.findStartingFiscalYearMonthForYearMonth(
      YM.plus(interval.end, 11, 'month'),
      fiscalMonth
    );
    invariant(YM.month(start) === fiscalMonth, 'should be true');
    invariant(YM.month(end) === fiscalMonth, 'should be true');
    return new YMInterval(start, end);
  }
}

export class FiscalYearSequence {
  readonly offsetMonth: number;
  readonly startYear: number;
  readonly endYearExclusive: number;
  readonly length: number;
  readonly intervalStarts: Array<YearMonth>;

  constructor(startYearMonth: YearMonth, endYearMonthExclusive: YearMonth) {
    invariant(
      YM.month(startYearMonth) === YM.month(endYearMonthExclusive),
      `months must be the same for start and end ${startYearMonth}, ${endYearMonthExclusive}`
    );
    invariant(
      endYearMonthExclusive > startYearMonth,
      `end year must be after start year ${endYearMonthExclusive}, ${startYearMonth}`
    );
    invariant(
      Math.abs(YM.diff(endYearMonthExclusive, startYearMonth, 'month')) % 12 ===
        0,
      'must be multiple of 12 months'
    );
    this.offsetMonth = YM.month(startYearMonth);
    this.startYear = YM.year(startYearMonth);
    this.endYearExclusive = YM.year(endYearMonthExclusive);
    this.length = this.endYearExclusive - this.startYear;
    this.intervalStarts = this.getIntervalStarts();
  }

  startYearMonth(): YearMonth {
    return YM.make(this.startYear, this.offsetMonth);
  }

  /**
   *
   * @returns the EXCLUSIVE end year month
   */
  endYearMonth(): YearMonth {
    return YM.make(this.endYearExclusive, this.offsetMonth);
  }

  entireInterval(): YMInterval {
    return new YMInterval(this.startYearMonth(), this.endYearMonth());
  }

  entireIntervalInclusive(): YMIntervalInclusiveEnd {
    return new YMIntervalInclusiveEnd(
      this.startYearMonth(),
      YM.minus(this.endYearMonth(), 1, 'year')
    );
  }

  allIntervals(): Array<FiscalYear> {
    let currInterval = new FiscalYear(
      this.startYearMonth(),
      YM.plus(this.startYearMonth(), 1, 'year')
    );
    const entireInterval = this.entireInterval();
    const intervals = [];
    while (entireInterval.containsInterval(currInterval)) {
      intervals.push(currInterval);
      currInterval = new FiscalYear(
        currInterval.end,
        YM.plus(currInterval.end, 1, 'year')
      );
    }
    return intervals;
  }

  private getIntervalStarts() {
    return this.allIntervals().map((i) => i.start);
  }

  fiscalYearsDiff(a: YearMonth, minusB: YearMonth): number {
    // invariant(a > minusB, 'to reduce ambiguity');
    invariant(
      YM.month(minusB) === this.offsetMonth && YM.month(a) === this.offsetMonth,
      'months must align'
    );
    return YM.diff(a, minusB, 'year');
  }

  static fromInterval(interval: YMInterval): FiscalYearSequence {
    invariant(
      YM.month(interval.start) === YM.month(interval.end),
      'must be fy aligned'
    );
    invariant(interval.months().length > 1, 'must be a non-zero interval');
    return new FiscalYearSequence(interval.start, interval.end);
  }

  static fromIntervalByOffsetMonth(
    interval: YMInterval,
    offsetMonth: number
  ): FiscalYearSequence {
    let base = interval.start;
    let end = interval.end;
    // move the start month back to encapsulate the FY
    while (YM.month(base) !== offsetMonth) {
      base = YM.minus(base, 1, 'month');
    }
    // move the end month forward to encapsulate the FY
    while (YM.month(end) !== offsetMonth) {
      end = YM.plus(end, 1, 'month');
    }
    return new FiscalYearSequence(base, end);
  }
}

export function unionYMIntervals(
  ...ymIntervals: Array<YMInterval>
): YMInterval {
  invariant(
    ymIntervals.length > 0,
    'must have at least one interval to call unionYMIntervals'
  );
  const allYMs = ymIntervals.flatMap((interval) => [
    interval.start,
    interval.end,
  ]);
  const minYM = YM.min(...allYMs);
  const maxYM = YM.max(...allYMs);
  return new YMInterval(minYM, maxYM);
}

/**
 * Given a list of YearMonth, returns the most precise set of intervals that
 * contains all the months in the list.
 *
 * For example, given [202201, 202202, 202205], the intervals returned are:
 * 202201-202203
 * 202205-202206
 */
export function monthsToIntervals(months: Array<YearMonth>): Array<YMInterval> {
  if (months.length === 0) {
    return [];
  }

  months.sort();
  const intervals: Array<YMInterval> = [];
  let startMonth = months[0];
  let currMonth = months[0];

  for (let i = 1; i < months.length; i += 1) {
    const nextMonth = YM.plus(currMonth, 1, 'month');
    if (nextMonth !== months[i]) {
      intervals.push(new YMInterval(startMonth, nextMonth));
      startMonth = months[i];
    }
    currMonth = months[i];
  }

  intervals.push(new YMInterval(startMonth, YM.plus(currMonth, 1, 'month')));
  return intervals;
}
