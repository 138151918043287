import gql from 'graphql-tag';
import React, { useState, useEffect, useContext } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import {
  GQOnboardingKind,
  GQActiveOrganizationFieldsFragment,
  GQWatershedPlanLegacy,
  GQRegion,
  GQUser,
  GQFootprintKindMetaFieldsFragment,
  GQPermissionType,
  GQFundAllFieldsFragment,
  GQActiveOrganizationSavedViewFragment,
  GQPermissionObjectType,
  GQWatershedPlan,
  GQFlags,
  GQOrgLimitedProfile,
  GQOrgPointOfContactKind,
} from '@watershed/shared-universal/generated/graphql';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import { YM, YMInterval } from '@watershed/shared-universal/utils/YearMonth';
import isNotNullish from '@watershed/shared-universal/utils/isNotNullish';
import {
  watershedPlanIncludesMeasurement,
  watershedPlanIncludesReductions,
  watershedPlanIncludesReporting,
} from '@watershed/shared-universal/utils/watershedPlanUtils';
import {
  CurrencyCode,
  getCurrencyForOrg,
} from '@watershed/shared-universal/utils/currencies';
import type { FeatureFlagsMap } from './FeatureFlag';

interface UserContextData {
  orgId: string;
  orgName: string;
  orgHelpChannel: string | null;
  orgCountry: string | null;
  demoOrg: boolean;
  testOrg: boolean;
  stagingOrg: boolean | null;
  region: GQRegion;
  watershedPlanLegacy: GQWatershedPlanLegacy;
  watershedPlan: GQWatershedPlan;
  watershedPlanIncludesMeasurement: boolean;
  watershedPlanIncludesReductions: boolean;
  watershedPlanIncludesDatasetCadences: boolean;
  watershedPlanIncludesReporting: boolean;
  userId: string;
  createdAt: Date;
  accessibleOrgs: Array<Pick<GQOrgLimitedProfile, 'companyId' | 'id' | 'name'>>;
  userName: string;
  userEmail: string;
  userIsWatershedEmployee: boolean;
  userIsWatershedContractor: boolean;
  userIsE2ETester: boolean;
  permissions: Array<{
    permission: GQPermissionType;
    objectId?: string | null;
    objectType?: GQPermissionObjectType | null;
  }>;
  userOnboardingsCompleted: Array<GQOnboardingKind>;
  latestPublishedFootprintVersion: string | null;
  latestDraftFootprintVersion: string | null;
  footprintInterval: YMInterval | null;
  draftFootprintInterval: YMInterval | null; // this field is non-null when there is an active draft footprint
  footprintKindsMeta: Array<GQFootprintKindMetaFieldsFragment>;
  logoUrl: string | null;
  iconUrl: string | null;
  loginAsUser: Pick<GQUser, 'id' | 'name'> | null;
  watershedEmployeePointOfContactKinds: Array<GQOrgPointOfContactKind>;
  isCustomer: boolean;
  hasInitialized: boolean;

  // #WinFinance global values for Sidebar
  funds: ReadonlyArray<GQFundAllFieldsFragment>;
  financeSavedViews: ReadonlyArray<GQActiveOrganizationSavedViewFragment>;

  orgFiscalYearStartMonth: number | null;
  canAccessCorporate: boolean;
  canAccessFinance: boolean;
  currency: CurrencyCode | null;
  companyId: string;
  domains: Array<string>;
  sessionId: string;
  preferredLocale: string | null;
}

export interface UserContextProps extends UserContextData {}

const UserContextDataDefaults: UserContextData = {
  orgId: 'string',
  orgName: 'string',
  orgHelpChannel: null,
  orgCountry: null,
  demoOrg: false,
  testOrg: false,
  stagingOrg: false,
  region: GQRegion.Us,
  watershedPlan: GQWatershedPlan.Standard,
  watershedPlanLegacy: GQWatershedPlanLegacy.Standard,
  watershedPlanIncludesMeasurement: false,
  watershedPlanIncludesReductions: false,
  watershedPlanIncludesDatasetCadences: false,
  watershedPlanIncludesReporting: false,
  userId: 'string',
  createdAt: new Date(),
  accessibleOrgs: [],
  userName: 'string',
  userEmail: 'string',
  userIsWatershedEmployee: false,
  userIsWatershedContractor: false,
  userIsE2ETester: false,
  permissions: [],
  userOnboardingsCompleted: [],
  footprintInterval: null,
  draftFootprintInterval: null,
  latestPublishedFootprintVersion: null,
  latestDraftFootprintVersion: null,
  footprintKindsMeta: [],
  logoUrl: null,
  iconUrl: null,
  loginAsUser: null,
  watershedEmployeePointOfContactKinds: [],
  isCustomer: true,
  hasInitialized: false,
  funds: [],
  financeSavedViews: [],
  orgFiscalYearStartMonth: null,
  canAccessCorporate: true,
  canAccessFinance: false,
  currency: null,
  companyId: '',
  domains: [],
  sessionId: '',
  preferredLocale: null,
};

const UserContextDefaults = UserContextDataDefaults;

const UserContext = React.createContext<UserContextProps>(UserContextDefaults);

export function useUserContext() {
  return useContext(UserContext);
}

// This giant list of stuff seems suspicious,
// as if pieces belong closer to where they are used…
gql`
  query ActiveOrganization {
    activeOrganization {
      ...ActiveOrganizationFields
    }
    flags {
      ...FlagsInitFields
    }
    preferredLocale
  }

  query ForceRedirect($pathname: String) {
    forceRedirect(pathname: $pathname)
  }

  # This minimal query is used simply to check whether or not the user
  # is authenticated: the request will succeed it they are, get a 401
  # error they are not.
  query CheckUserAuthentication {
    activeOrganization {
      id
    }
  }

  fragment FootprintKindMetaFields on FootprintKindMeta {
    id
    name
    description
    footprintInterval
    footprintDraftInterval
  }

  fragment PermissionDetails on PermissionItem {
    id
    permission
    object {
      __typename
      id
    }
  }

  fragment ActiveOrganizationSavedView on FinanceSavedView {
    id
    name
  }

  fragment ActiveOrganizationFund on Fund {
    id
    name
    nameSortable
    fundGroup
    fundCategory
    excludeAsSandbox
  }

  # Be careful about adding new fields to this fragment or child-fragments: this is a blocking query on every page load.
  fragment ActiveOrganizationFields on ActiveOrganization {
    # Simple things on the org model or context
    id
    name
    helpChannel
    demoOrg
    testOrg
    stagingOrg
    region
    watershedPlan
    watershedPlanLegacy
    canAccessFinance
    canAccessCorporate
    fiscalYearStartMonth
    currency
    country
    companyId
    domains
    logoUrl
    iconUrl
    sessionId

    # User and logged in user (one step more complicated)
    user {
      id
      createdAt
      name
      accessibleOrgs {
        id
        name
        companyId
      }
      email
      isWatershedEmployee
      isWatershedContractor
      isE2ETester
    }
    loginAsUser {
      id
      name
      isWatershedEmployee
      isWatershedContractor
      isE2ETester
    }

    # Used all over the place. Onboarding could use more ownership? :shrug:
    userOnboardingsCompleted

    # Calcprint fields
    # https://linear.app/watershed/issue/ENT-770/audit-calcprint-fields-in-activeorganizationquery
    latestPublishedFootprintVersion
    latestDraftFootprintVersion
    footprintKindsMeta {
      ...FootprintKindMetaFields
    }
    watershedEmployeePointOfContactKinds
    
    # TODO: Remove from query as part of Permissions v2
    # https://linear.app/watershed/issue/ENT-769/remove-permissions-from-activeorganizationquery
    userRoles {
      id
      role {
        id
        permissions {
          id
          ...PermissionDetails
        }
      }
    }
    userPermissions {
      id
      ...PermissionDetails
    }
    datasourceIdsToCompletePermissions

    # TODO: Move to separate query https://linear.app/watershed/issue/FIN-6659/split-out-finance-sidebar-query
    funds {
      id
      ...FundAllFields
    }
    financeSavedViews {
      id
      ...ActiveOrganizationSavedView
    }
  }

  fragment FlagsInitFields on FeatureFlag {
    id
    name
    description
    activated
  }

  mutation ThrowError {
    throwError(input: { fooString: "abcd", password: "1234" }) {
      message
    }
  }
`;

function parseUserContextData(
  activeOrg: GQActiveOrganizationFieldsFragment,
  flags: FeatureFlagsMap,
  preferredLocale: string | null
): UserContextData {
  const user = activeOrg.user;
  const footprintKindsMeta: Array<GQFootprintKindMetaFieldsFragment> =
    activeOrg.footprintKindsMeta;

  // TODO: We should make a cleaner function to do the same thing.
  function getUnionInterval(
    footprintKindsMeta: Array<GQFootprintKindMetaFieldsFragment>,
    intervalKind: 'footprintInterval' | 'footprintDraftInterval'
  ) {
    if (!footprintKindsMeta.some((fkm) => fkm[intervalKind])) {
      return null;
    }
    const unionMin = YM.min(
      ...footprintKindsMeta
        .map((fkm) => fkm[intervalKind]?.start)
        .filter(isNotNullish)
    );
    const unionMax = YM.max(
      ...footprintKindsMeta
        .map((fkm) => fkm[intervalKind]?.end)
        .filter(isNotNullish)
    );
    return new YMInterval(unionMin, unionMax);
  }

  // Is this a real customer session?
  const isCustomer =
    !activeOrg.demoOrg &&
    !activeOrg.testOrg &&
    !activeOrg.loginAsUser &&
    !user.isWatershedEmployee &&
    !user.isWatershedContractor &&
    !user.isE2ETester;

  // TODO (reorganize all this code in a sane way)
  datadogLogs.setLoggerGlobalContext({
    orgId: activeOrg.id,
    orgName: activeOrg.name,
    demoOrg: activeOrg.demoOrg,
    testOrg: activeOrg.testOrg,
    stagingOrg: activeOrg.stagingOrg,
    watershedPlanLegacy: activeOrg.watershedPlanLegacy,
    userId: user.id,
    userName: user.name,
    loginAsUserName: activeOrg.loginAsUser?.name,
    isCustomer,
  });

  const currencyCode = getCurrencyForOrg(activeOrg);

  return {
    orgId: activeOrg.id,
    orgName: activeOrg.name,
    orgHelpChannel: activeOrg.helpChannel,
    orgCountry: activeOrg.country,
    demoOrg: activeOrg.demoOrg,
    testOrg: activeOrg.testOrg,
    stagingOrg: activeOrg.stagingOrg,
    region: activeOrg.region,
    watershedPlan: activeOrg.watershedPlan,
    watershedPlanLegacy: activeOrg.watershedPlanLegacy,
    watershedPlanIncludesMeasurement: watershedPlanIncludesMeasurement({
      watershedPlan: activeOrg.watershedPlan,
      watershedPlanLegacy: activeOrg.watershedPlanLegacy,
    }),
    watershedPlanIncludesReductions: watershedPlanIncludesReductions({
      watershedPlan: activeOrg.watershedPlan,
      watershedPlanLegacy: activeOrg.watershedPlanLegacy,
      overrideFlag: flags.get(GQFlags.PricingFy25ReductionsModuleTemp),
    }),
    watershedPlanIncludesReporting:
      watershedPlanIncludesReporting({
        watershedPlan: activeOrg.watershedPlan,
        watershedPlanLegacy: activeOrg.watershedPlanLegacy,
      }) ||
      !!flags.get(GQFlags.PricingFy25ReportingModuleTemp) ||
      !!flags.get(GQFlags.PricingFy25CsrdReportBuilderModuleTemp),
    watershedPlanIncludesDatasetCadences:
      activeOrg.watershedPlanLegacy === GQWatershedPlanLegacy.Pro ||
      activeOrg.watershedPlanLegacy === GQWatershedPlanLegacy.NetZero,
    userId: user.id,
    createdAt: user.createdAt,
    accessibleOrgs: user.accessibleOrgs,
    userName: user.name,
    userEmail: user.email,
    userIsWatershedEmployee: user.isWatershedEmployee,
    userIsWatershedContractor: user.isWatershedContractor,
    userIsE2ETester: user.isE2ETester,
    permissions: parseRawPermissions(
      activeOrg.userRoles,
      activeOrg.userPermissions,
      activeOrg.datasourceIdsToCompletePermissions
    ),
    userOnboardingsCompleted: activeOrg.userOnboardingsCompleted,
    latestPublishedFootprintVersion: activeOrg.latestPublishedFootprintVersion,
    latestDraftFootprintVersion: activeOrg.latestDraftFootprintVersion,
    footprintInterval: getUnionInterval(
      footprintKindsMeta,
      'footprintInterval'
    ),
    draftFootprintInterval: getUnionInterval(
      footprintKindsMeta,
      'footprintDraftInterval'
    ),
    footprintKindsMeta: activeOrg.footprintKindsMeta || [],
    logoUrl: activeOrg.logoUrl,
    iconUrl: activeOrg.iconUrl,
    loginAsUser: activeOrg.loginAsUser,
    watershedEmployeePointOfContactKinds:
      activeOrg.watershedEmployeePointOfContactKinds,
    isCustomer,
    hasInitialized: true,
    funds: activeOrg.funds ?? [],
    financeSavedViews: activeOrg.financeSavedViews ?? [],
    orgFiscalYearStartMonth: activeOrg.fiscalYearStartMonth,
    canAccessCorporate: activeOrg.canAccessCorporate,
    canAccessFinance: activeOrg.canAccessFinance,
    currency: currencyCode,
    companyId: activeOrg.companyId,
    domains: activeOrg.domains,
    sessionId: activeOrg.sessionId,
    preferredLocale,
  };
}

// TODO (adam): Where should this code live?
/*
 * If a user has ManageDataset permissions for some datasets, we add the ManageDatasource permission for datasources in those Datasets.
 */
function parseRawPermissions(
  userRoles: GQActiveOrganizationFieldsFragment['userRoles'],
  userPermissions: GQActiveOrganizationFieldsFragment['userPermissions'],
  datasourceIdsToCompletePermissions: GQActiveOrganizationFieldsFragment['datasourceIdsToCompletePermissions']
) {
  const rolePermissions = (userRoles || []).map(
    ({ role }) => role?.permissions || []
  );
  const additionalDatasourcePermissions =
    datasourceIdsToCompletePermissions.map((datasourceId) => ({
      permission: GQPermissionType.ManageDatasource,
      objectId: datasourceId,
      objectType: GQPermissionObjectType.Datasource,
    }));

  const permissions = [...(userPermissions || []), ...rolePermissions]
    .flat()
    .map(({ permission, object }) => ({
      permission: permission as GQPermissionType,
      objectId: object?.id,
      objectType: object?.__typename,
    }));

  return [...permissions, ...additionalDatasourcePermissions];
}

function useParsedUserContext(
  activeOrganization: GQActiveOrganizationFieldsFragment,
  flags: FeatureFlagsMap,
  preferredLocale: string | null
): UserContextProps {
  const [parsedContext, setParsedContext] = useState(() =>
    parseUserContextData(activeOrganization, flags, preferredLocale)
  );

  //  data in the context has changed (including overrides).
  // If it has, we'll generate a new context so the app re-renders itself correctly.
  const newParsedContextData = parseUserContextData(
    activeOrganization,
    flags,
    preferredLocale
  );
  const oldParsedContextData = omit(parsedContext, ['operations', 'overrides']);
  const shouldUpdateContext = !isEqual(
    newParsedContextData,
    oldParsedContextData
  );

  let updatedParsedContext: UserContextProps;
  if (shouldUpdateContext) {
    updatedParsedContext = newParsedContextData;
  } else {
    updatedParsedContext = parsedContext;
  }

  useEffect(() => {
    if (shouldUpdateContext) {
      setParsedContext(updatedParsedContext);
    }
  }, [shouldUpdateContext, updatedParsedContext]);

  return updatedParsedContext;
}

export { useParsedUserContext, UserContext };

export function useUserIsWatershedEmployeeOrContractor() {
  const { userIsWatershedContractor, userIsWatershedEmployee } =
    useUserContext();
  return userIsWatershedContractor || userIsWatershedEmployee;
}
